import OrganizationSwitcher from "@/components/organization/organizationSwitcher";
import { MainNav } from "@/components/header/mainNav";
import { UserNav } from "@/components/header/userNav";
import Logo from "@/components/svgs/Logo";

export function Header() {
  return (
    <>
      <div className="border-b">
        <div className="flex h-16 items-center px-4">
          <Logo className="mr-6 h-8 w-28 shrink-0 fill-current" />
          <OrganizationSwitcher />
          <MainNav className="mx-6" />
          <div className="ml-auto flex items-center space-x-4">
            <UserNav />
          </div>
        </div>
      </div>
    </>
  );
}
