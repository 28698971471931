import { PageContainer } from "@/components/PageContainer";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FieldGenerationParcel } from "@/model/field.typing";
import { useFetchFieldDetail, useFieldGenerationDetail } from "@/query/field.query";
import { LanguageCode, getLocale } from "@/utils/locale";
import clsx from "clsx";
import { formatRelative } from "date-fns";
import L from "leaflet";
import { ArrowLeft, MenuIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Polygon, Popup, TileLayer, useMap } from "react-leaflet";
import { Link, useParams } from "react-router-dom";

export function FieldMapPage() {
  const { t, i18n } = useTranslation();
  const [activeParcel, setActiveParcel] = useState<string | null>(null);
  const itemRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const locale = getLocale(i18n.language as LanguageCode);
  const params = useParams<{ id: string; generation: string }>();
  const { field } = useFetchFieldDetail(params.id);
  const { data, isLoading } = useFieldGenerationDetail(params.id as string, Number(params.generation));
  const generation = field?.generations.find((g) => g.generation === Number(params.generation));

  useEffect(() => {
    if (activeParcel && itemRefs.current[activeParcel]) {
      itemRefs.current[activeParcel].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeParcel]);

  if (isLoading || !data || !field) {
    return null;
  }

  return (
    <PageContainer
      title={`${field.name} - ${params.generation}`}
      description={
        generation ? (
          <span className="inline-flex gap-2">
            <span>{t("fields.labels.uploaded")}</span>
            <span>{formatRelative(generation.upload_date, new Date(), { locale })}</span>
          </span>
        ) : (
          ""
        )
      }
      actionsRender={() => {
        return (
          <Button asChild>
            <Link to={`/app/case-study/${field.id}`} className="flex flex-nowrap items-center space-x-1">
              <ArrowLeft className="h-5 w-5" />
              <span>{t("fields.back-to-field")}</span>
            </Link>
          </Button>
        );
      }}
    >
      <div className="mt-4 grid min-h-[600px] grid-cols-[min-content_auto] gap-4">
        <div className="py-2">
          <h4 className="mb-4 text-sm font-medium leading-none">
            {t("fields.all-parcels")} ({data.parcels.length})
          </h4>
          <Command className="min-w-[200px]">
            <CommandInput placeholder={t("fields.search")} />
            <CommandList className="max-h-[600px]">
              <CommandEmpty>{t("fields.no-results")}</CommandEmpty>
              <CommandGroup>
                {data.parcels
                  .sort((a, b) => {
                    // sort parcels by id
                    return a.id_parcel.localeCompare(b.id_parcel);
                  })
                  .map((parcel) => (
                    <CommandItem
                      key={parcel.id_parcel}
                      value={parcel.id_parcel}
                      className={clsx(
                        "flex items-center justify-between",
                        activeParcel === parcel.id_parcel && "rounded-md border border-primary bg-muted"
                      )}
                      ref={(el) => (itemRefs.current[parcel.id_parcel] = el as HTMLDivElement)}
                    >
                      <div
                        key={parcel.id_parcel}
                        className="cursor-pointer text-sm"
                        onClick={() => setActiveParcel(parcel.id_parcel)}
                      >
                        <span>{parcel.id_parcel}</span>
                        <div className="whitespace-nowrap text-xs text-muted-foreground">
                          <span>{parcel.sequence.join(", ")}</span>
                        </div>
                      </div>
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <Button size={"icon"} variant={"ghost"}>
                            <MenuIcon className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem asChild>
                            <Link
                              to={`/app/simulations/add/${field.id}/${generation?.generation}?parcelId=${parcel.id_parcel}`}
                            >
                              {t("fields.create-simulation")}
                            </Link>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </div>

        <MapComponent selectedParcelId={activeParcel} onSelectParcel={setActiveParcel} parcels={data.parcels} />
      </div>
    </PageContainer>
  );
}

function MapComponent({
  selectedParcelId,
  parcels,
  onSelectParcel,
}: {
  parcels: FieldGenerationParcel[];
  selectedParcelId: string | null;
  onSelectParcel: (id: string) => void;
}) {
  const { t } = useTranslation();
  const params = useParams<{ id: string; generation: string }>();
  const generation = Number(params.generation);
  const fieldId = params.id;
  return (
    <MapContainer zoom={13} style={{ height: "100%", width: "100%" }} className="z-10" attributionControl={false}>
      <TileLayer
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={"map-tiles"}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {parcels.map((parcel: FieldGenerationParcel) => (
        <Polygon
          key={parcel.id_parcel}
          pathOptions={
            parcel.id_parcel === selectedParcelId ? { color: "red", weight: 5 } : { color: "blue", weight: 2 }
          }
          positions={parcel.geometry.coordinates[0].map((coord) => {
            const [lat, long] = coord;
            return new L.LatLng(long, lat);
          })}
          eventHandlers={{
            click: () => onSelectParcel(parcel.id_parcel),
          }}
        >
          <Popup>
            <strong>{parcel.id_parcel}</strong>
            <div className="text-xs text-muted-foreground">
              <span>{parcel.sequence.join(", ")}</span>
            </div>
            <Button variant="default" size="sm" className="mt-2">
              <Link to={`/app/simulations/add/${fieldId}/${generation}?parcelId=${parcel.id_parcel}`}>
                {t("fields.create-simulation")}
              </Link>
            </Button>
          </Popup>
        </Polygon>
      ))}
      <SetViewToBounds parcels={parcels} />
    </MapContainer>
  );
}

const SetViewToBounds = ({ parcels }: { parcels: FieldGenerationParcel[] }) => {
  const map = useMap();

  useEffect(() => {
    if (map) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const bounds = new L.LatLngBounds();
      parcels.forEach((parcel) => {
        parcel.geometry.coordinates[0].forEach((coord) => {
          bounds.extend(new L.LatLng(coord[1], coord[0]));
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, parcels]);

  return null;
};
