export function toNumber(value: unknown): number | null {
  if (typeof value === "number") {
    return value;
  }
  if (typeof value === "string") {
    const numberValue = parseInt(value, 10);
    return isNaN(numberValue) ? null : numberValue;
  }
  return null;
}

export function toBoolean(value: unknown): boolean | null {
  if (typeof value === "boolean") {
    return value;
  }
  if (typeof value === "number") {
    return value === 1 ? true : value === 0 ? false : null;
  }
  if (typeof value === "string") {
    const parsedValue = value.trim().toLowerCase();
    return parsedValue === "true" || parsedValue === "on"
      ? true
      : parsedValue === "false" || parsedValue === "off"
      ? false
      : null;
  }
  return null;
}
