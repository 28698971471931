import { Button } from "@/components/ui/button";
import { PropsWithChildren } from "react";
import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 404:
        return (
          <ErrorLayout
            status={error.status}
            title="Page not found"
            description="Please check the URL in the address bar and try again."
            action={{ to: "/app", label: "Go back home" }}
          />
        );
      case 400:
        return (
          <ErrorLayout
            status={error.status}
            title="Bad Request"
            description="We could not process your request. Please try again."
            action={{ to: "/app", label: "Reload the app", forceReload: true }}
          />
        );
      case 401:
        return (
          <ErrorLayout
            status={error.status}
            title="Unauthorized"
            description="Please try to sign-in again. Your account has expired."
            action={{ to: "/signin", label: "Login" }}
          />
        );
      case 403:
        return (
          <ErrorLayout
            status={error.status}
            title="Forbidden"
            description="Sorry you don't have permission to access this page or to perform this action."
            action={{ to: "/app", label: "Go back home" }}
          />
        );

      default:
        return <ServerError data={error.data} />;
    }
  }

  const message = (error as { message?: string })?.message ?? null;
  return <ServerError message={message} />;
}

function ServerError({ message, data }: { message?: string | null; data?: unknown }) {
  return (
    <ErrorLayout
      status={500}
      title="Oops!"
      description="Sorry, an unexpected error has occurred."
      action={{ to: "/app", label: "Reload the app", forceReload: true }}
    >
      <p className="mt-1 inline-flex flex-nowrap space-x-2 text-sm text-gray-500">
        {message ? (
          <>
            <span className="font-medium">Error message:</span>
            <i>{message}</i>
          </>
        ) : null}
        {data ? (
          <>
            <span className="font-medium">Error details:</span>
            <pre>{JSON.stringify(data)}</pre>
          </>
        ) : null}
      </p>
    </ErrorLayout>
  );
}

interface Props {
  status: number;
  title: string;
  description?: string;
  action?: { label: string; to: string; forceReload?: boolean; onClick?: () => void };
}

export function ErrorLayout({ status, title, description, action, children }: PropsWithChildren<Props>) {
  const navigate = useNavigate();
  return (
    <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-blue-600 sm:text-5xl">{status}</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{title}</h1>
              <p className="mt-1 text-base text-gray-500">{description}</p>
              {children}
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              {action ? (
                <Button
                  type="button"
                  onClick={() =>
                    action.onClick
                      ? action?.onClick()
                      : action.forceReload
                      ? (window.location.href = action.to)
                      : navigate(action.to)
                  }
                >
                  {action.label}
                </Button>
              ) : null}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
