import { PageContainer } from "@/components/PageContainer";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { FieldBaseGenerationType } from "@/model/field.typing";
import { useFetchFieldDetail, useFieldGenerationDetail } from "@/query/field.query";
import { useGenerateDownloadLink } from "@/query/simulation.query";
import { LanguageCode, getLocale } from "@/utils/locale";
import { format, formatRelative } from "date-fns";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

type FieldDetailPageParams = {
  fieldId: string;
  fieldGeneration: string;
  organizationId: string;
  simulationId: string;
};

function SimulatedPage() {
  const { fieldId, fieldGeneration, organizationId, simulationId } = useParams<FieldDetailPageParams>();
  const { field, isLoading } = useFetchFieldDetail(fieldId, Number(organizationId));

  if (isLoading || !field) {
    return null;
  }

  const generation = field.generations.find((generation) => generation.generation === Number(fieldGeneration));

  if (!generation) {
    return null;
  }
  return (
    <PageContainer title={field.name} description={field.description ?? ""}>
      <PageContent
        fieldId={fieldId as string}
        generation={generation}
        organizationId={Number(organizationId)}
        simulationId={simulationId as string}
      />
    </PageContainer>
  );
}

interface FieldDetailPageProps {
  fieldId: string;
  organizationId: number;
  simulationId: string;
  generation: FieldBaseGenerationType;
}

function PageContent({ fieldId, organizationId, simulationId, generation }: FieldDetailPageProps) {
  const { t, i18n } = useTranslation();
  const locale = getLocale(i18n.language as LanguageCode);
  const { data } = useFieldGenerationDetail(fieldId, generation.generation);
  const { mutate, isLoading: isDownloading } = useGenerateDownloadLink();
  return (
    <div className="mt-4">
      <Card className="mt-4">
        <CardContent className="pt-6">
          <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
            <div className="grid flex-1 grid-cols-1 gap-x-4 gap-y-2 text-sm text-muted-foreground md:grid-cols-2">
              <div className="flex items-center gap-1">
                <span className="whitespace-nowrap">{t("fields.labels.generation")}</span>
                <span className="text-sm font-semibold text-primary">{generation.generation}</span>
              </div>
              <div className="flex items-center gap-1">
                <span className="whitespace-nowrap">{t("fields.labels.parcelCount")}</span>
                <span className="text-sm font-semibold text-primary">{data?.parcels_count ?? 0}</span>
              </div>
              <div className="flex items-center gap-1">
                <span className="whitespace-nowrap">{t("fields.labels.pedoZones")}</span>
                <span className="text-sm font-semibold text-primary">{data?.pedo_zones.join(",")}</span>
              </div>
              <div className="flex items-center gap-1">
                <span className="whitespace-nowrap">{t("fields.labels.minMaxYear")}</span>
                <span className="text-sm font-semibold text-primary">
                  {data?.min_year} - {data?.max_year}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <span>{t("fields.labels.uploaded")}</span>
                <span
                  className="whitespace-nowrap text-sm font-semibold capitalize text-primary"
                  title={format(generation.upload_date, "PPp", { locale })}
                >
                  {formatRelative(generation.upload_date, new Date(), { locale })}
                </span>
              </div>
            </div>

            <div>
              <div className="capitalize">
                <Button
                  disabled={isDownloading}
                  variant="ghost"
                  size="sm"
                  onClick={() => mutate({ organizationId, simulationId })}
                >
                  {t("simulations.column.downloadFiles")}
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default SimulatedPage;
