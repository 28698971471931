import { organizationQuery } from "@/query/organization.query";
import { OrganizationDetailView } from "@/routes/common/orgnizationDetail";
import { useCurrentOrganizationStore } from "@/store/useCurrentOrganizationStore";
import { useQuery } from "@tanstack/react-query";

export function DashboardPage() {
  const { currentOrganization } = useCurrentOrganizationStore();
  const { data: organization } = useQuery(organizationQuery(currentOrganization?.id ?? 0));
  if (!organization) {
    return null;
  }
  return <OrganizationDetailView organization={organization} />;
}
