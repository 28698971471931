import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { mapToAppError } from "utils/error";

export const TOKEN_KEY = "maelia-session";

const api: AxiosInstance = axios.create({
  baseURL: import.meta.env.MAELIA_API_SERVER_URL,
  timeout: 30000,
  withCredentials: true, // attach auth. cookie by default
  xsrfCookieName: "XSRF-TOKEN", // should match backend naming
  xsrfHeaderName: "X-XSRF-TOKEN",
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (axiosError: AxiosError): Promise<AxiosError> => {
    const error = mapToAppError(axiosError);
    return Promise.reject(error);
  }
);
api.interceptors.request.use(
  (requestConfig: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token !== null) {
      requestConfig.headers.setAuthorization(`Bearer ${token}`);
    }
    return requestConfig;
  },
  (axiosError: AxiosError): Promise<AxiosError> => {
    return Promise.reject(axiosError);
  }
);

export default api;
