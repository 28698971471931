import Anchor from "@/components/ui/anchor";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDidMount } from "rooks";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";

const COOKIE_CONSENT_KEY = "maelia-cookie-consent";

function CookieConsentProvider() {
  const [isShown, setIsShown] = useState<boolean>(false);

  useDidMount(() => {
    const value = getLocalStorageValue(COOKIE_CONSENT_KEY, "read-cookie-consent");
    setIsShown(() => !value);
  });

  if (isShown) {
    return (
      <CookieMessage
        onCookieAccept={() => {
          setIsShown(false);
          setLocalStorageValue(COOKIE_CONSENT_KEY, "accepted", "set-cookie-consent-accepted");
        }}
      />
    );
  }

  return null;
}

function CookieMessage({ onCookieAccept }: { onCookieAccept: () => void }) {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-x-0 bottom-0 mx-auto max-w-7xl px-2 pb-2 sm:px-6 sm:pb-5 lg:px-8">
      <div className="rounded-lg bg-blue-600 p-2 shadow-lg sm:p-3">
        <div className="flex flex-col items-center justify-between md:flex-row md:space-x-2">
          <div className="w-full items-center">
            <p className="font-medium text-white dark:text-slate-200">
              {t("cookie-consent.message")}
              <Anchor
                href={"/app/legal/privacy"}
                label={t("footer.privacy")}
                className="px-0 text-secondary dark:text-secondary-foreground"
              />
            </p>
          </div>
          <div className="mt-2 w-full md:mt-0 md:w-max">
            <Button variant="secondary" onClick={onCookieAccept}>
              {t("cookie-consent.accept")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookieConsentProvider;
