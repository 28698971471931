import { FieldWithGenerationsSchema } from "@/model/field.typing";
import { SimulationSchema } from "@/model/simulation.typing";
import { UserSchema } from "@/model/user.typing";
import { z } from "zod";

export const BaseOrganizationSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const OrganizationDetailSchema = z
  .object({
    users: z.array(z.object({}).merge(UserSchema)),
    simulations: z.array(SimulationSchema),
    fields: z.array(FieldWithGenerationsSchema),
  })
  .merge(BaseOrganizationSchema);

export const OrganizationSchema = z
  .object({
    user_count: z.number(),
    case_studies_count: z.number(),
    simulation_count: z.number(),
  })
  .merge(BaseOrganizationSchema);
export type OrganizationType = z.infer<typeof OrganizationSchema>;
export type OrganizationDetailType = z.infer<typeof OrganizationDetailSchema>;
