import api from "@/api/api";
import {
  OrganizationDetailSchema,
  OrganizationDetailType,
  OrganizationSchema,
  OrganizationType,
} from "@/model/organization.typing";
import { z } from "zod";

export async function fetchOrganizations(signal?: AbortSignal): Promise<OrganizationType[]> {
  const response = await api.get<Array<object>>("/private/v1/organizations", {
    signal,
  });
  if (!response.data) {
    return [];
  }
  return z.array(OrganizationSchema).parse(response.data);
}

export async function fetchUserOrganizations(signal?: AbortSignal): Promise<OrganizationType[]> {
  const response = await api.get<Array<object>>("/v1/organizations", {
    signal,
  });
  if (!response.data) {
    return [];
  }
  return z.array(OrganizationSchema).parse(response.data);
}

export async function fetchOrganization(
  organizationId: number,
  signal?: AbortSignal
): Promise<OrganizationDetailType | null> {
  const response = await api.get<Array<object>>(`/v1/organizations/${organizationId}`, {
    signal,
  });
  if (response.data === null) {
    return null;
  }
  return OrganizationDetailSchema.parse(response.data);
}

export async function requestCreateOrganization(
  body: {
    name: string;
  },
  signal?: AbortSignal
) {
  await api.post<void>("/private/v1/organizations", body, { signal });
}

export async function requestUpdateOrganization(
  id: number,
  body: {
    name: string;
  },
  signal?: AbortSignal
) {
  await api.put<void>(`/private/v1/organizations/update/${id}`, body, { signal });
}
