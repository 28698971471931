import { FormControl } from "@/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useUserOrganizationList } from "@/query/organization.query";
import { toNumber } from "@/utils/mapping";

interface Props {
  placeholder: string;
  defaultValue: number | undefined;
  onValueChange: (value: number | null) => void;
}

export const OrganizationSelect = ({ placeholder, defaultValue, onValueChange }: Props) => {
  const { data: organizations = [], isLoading } = useUserOrganizationList();
  return (
    <Select onValueChange={(value) => onValueChange(toNumber(value))} defaultValue={defaultValue?.toString()}>
      <FormControl>
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
      </FormControl>
      {isLoading ? null : (
        <SelectContent>
          {organizations.map(({ id, name }) => (
            <SelectItem key={id} value={id.toString()}>
              {name}
            </SelectItem>
          ))}
        </SelectContent>
      )}
    </Select>
  );
};
