import FormError from "@/components/form/formError";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { OrganizationType } from "@/model/organization.typing";
import { useCreateOrUpdateOrganizationMutation, useOrganizationList } from "@/query/organization.query";
import { cn } from "@/utils/utils";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { SelectIcon } from "@radix-ui/react-select";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface Props {
  selectedIds: number[];
  onSelect: (selected: OrganizationType[]) => void;
}

export default function OrganizationMultiSelect({ selectedIds, onSelect }: Props) {
  const { t } = useTranslation();
  const [showNewTeamDialog, setShowNewTeamDialog] = useState(false);
  const { data: organizations = [] } = useOrganizationList();
  const selected = useMemo(() => organizations.filter((o) => selectedIds.includes(o.id)), [organizations, selectedIds]);

  return (
    <Dialog open={showNewTeamDialog} onOpenChange={setShowNewTeamDialog}>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm" className="h-9 border">
            <SelectIcon className="mr-2 h-4 w-4" />
            Organizations
            {selected?.length > 0 && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />
                <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                  {selected.length}
                </Badge>
                <div className="hidden space-x-1 lg:flex">
                  {selected.length > 2 ? (
                    <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                      {selected.length} selected
                    </Badge>
                  ) : (
                    selected.map((option) => (
                      <Badge variant="secondary" key={option.id} className="rounded-sm px-1 font-normal">
                        {option.name}
                      </Badge>
                    ))
                  )}
                </div>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[300px] p-0" align="start">
          <Command>
            <CommandInput placeholder="Search" />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {organizations.map((option) => {
                  const isSelected = selected.includes(option);
                  return (
                    <CommandItem
                      key={option.id}
                      onSelect={() => {
                        const filterValues = isSelected
                          ? selected.filter((o) => o.id !== option.id)
                          : [...selected, option];
                        onSelect(filterValues);
                      }}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                          isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible"
                        )}
                      >
                        <CheckIcon className={cn("h-4 w-4")} />
                      </div>
                      <span>{option.name}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              <CommandSeparator />
              <CommandGroup>
                <DialogTrigger asChild>
                  <CommandItem
                    className="justify-center text-center"
                    onSelect={() => {
                      setShowNewTeamDialog(true);
                    }}
                  >
                    <PlusCircledIcon className="mr-2 h-5 w-5" />
                    {t("create-organization.create")}
                  </CommandItem>
                </DialogTrigger>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <CreateOrganizationDialog onClose={() => setShowNewTeamDialog(false)} />
    </Dialog>
  );
}

export const CreateOrganizationDialog = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { form, onSubmit, i18nErrorMessage, onReset, isLoading, isSuccess } = useCreateOrUpdateOrganizationMutation();
  useEffect(() => {
    if (isSuccess) {
      onClose();
      onReset();
    }
  }, [isSuccess, onClose, onReset]);

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Create team</DialogTitle>
        <DialogDescription>Add a new team to manage products and customers.</DialogDescription>
      </DialogHeader>
      <Form {...form}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
          <div>
            <div className="space-y-6 py-2 pb-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("create-organization.organization.label")}</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        autoComplete="name"
                        placeholder={t("create-organization.organization.placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => onClose()}>
              {t("create-organization.cancel")}
            </Button>
            <Button type="submit" disabled={isLoading}>
              {t("create-organization.create")}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
};
