import { AnchorHTMLAttributes, PropsWithChildren } from "react";
import { buttonVariants } from "@/components/ui/button";

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  label: string;
}

function Anchor({ label, className = "", children, ...props }: PropsWithChildren<Props>) {
  return (
    <a {...props} className={buttonVariants({ variant: "link", className })}>
      {children ? children : label}
    </a>
  );
}

export default Anchor;
