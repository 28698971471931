import { PageContainer } from "@/components/PageContainer";
import AnchorLink from "@/components/ui/anchorLink";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { OrganizationDetailType } from "@/model/organization.typing";
import { User, getUserInitials, isAdmin } from "@/model/user.typing";
import { useLoggedUser } from "@/query/user.query";
import { LanguageCode, getLocale } from "@/utils/locale";
import { cn } from "@/utils/utils";
import { format } from "date-fns";
import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

export const OrganizationDetailView = ({ organization }: { organization: OrganizationDetailType }) => {
  const { t } = useTranslation();
  const { data: loggedUser } = useLoggedUser();
  const isUserAdmin = isAdmin(loggedUser);
  return (
    <PageContainer title={organization.name} description={t("organization.description")}>
      <div className="grid grid-cols-2 items-start justify-center gap-6 rounded-lg py-8">
        <div className="col-span-2 grid items-start gap-6 lg:col-span-1">
          <Container>
            <Simulations simulations={organization.simulations} />
          </Container>
          <Container>
            <Fields fields={organization.fields} />
          </Container>
        </div>
        <div className="col-span-2 grid items-start gap-6 lg:col-span-1">
          <Container>
            <OrganizationMembers users={organization.users} isAdmin={isUserAdmin} />
          </Container>
        </div>
      </div>
    </PageContainer>
  );
};

function OrganizationMembers({ users, isAdmin }: { users: User[]; isAdmin: boolean }) {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader className="mb-4">
        <div className="flex flex-row items-center justify-between">
          <CardTitle className="text-xl">{t("organization.members.title")}</CardTitle>
          {isAdmin ? (
            <div>
              <AnchorLink to={"/app/admin/users/add"} label={t("organization.members.add")} />
            </div>
          ) : null}
        </div>
      </CardHeader>
      <CardContent className="grid max-h-[600px] gap-6 overflow-y-auto">
        {users.length === 0 ? (
          <div className="py-6 text-center text-sm text-muted-foreground">{t("error.no-result")}</div>
        ) : null}
        {users.map((user) => (
          <div key={user.id} className="flex items-center justify-between space-x-4">
            <div className="flex items-center space-x-4">
              <Avatar>
                <AvatarFallback>{getUserInitials(user.fullname)}</AvatarFallback>
              </Avatar>
              <div>
                <AnchorLink
                  to={`/app/admin/users/edit/${user.id}`}
                  label={user.fullname}
                  className="text-sm font-medium capitalize leading-none"
                />
                <p className="text-sm text-muted-foreground">{user.email}</p>
              </div>
            </div>
            {isAdmin ? (
              <div className="flex gap-2">
                <Badge variant={user.activated ? "outline" : "secondary"}>
                  {user.activated ? t("users.column.active") : t("users.column.inactive")}
                </Badge>
                <Badge variant={user.profile.role === "ADMIN" ? "default" : "secondary"} className="capitalize">
                  {user.profile.role.toLowerCase()}
                </Badge>
              </div>
            ) : null}
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

function Fields({ fields }: Pick<OrganizationDetailType, "fields">) {
  const { t, i18n } = useTranslation();
  const locale = getLocale(i18n.language as LanguageCode);
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">{t("organization.fields.title")}</CardTitle>
      </CardHeader>
      <CardContent className="grid max-h-[600px] gap-6 overflow-y-auto">
        {fields.length === 0 ? (
          <div className="py-6 text-center text-sm text-muted-foreground">{t("error.no-result")}</div>
        ) : null}
        {fields.map((field) => (
          <div key={field.id} className="flex flex-col gap-2">
            <div className="flex items-center justify-between space-x-4">
              <div>
                <AnchorLink
                  to={`/app/case-study/${field.id}`}
                  label={field.name}
                  className="text-sm font-medium capitalize leading-none"
                />
              </div>

              <div>
                <Badge variant={"outline"}>
                  {t("organization.fields.generations")} {field.generations.length}
                </Badge>
              </div>
            </div>
            <div className="mx-4">
              {field.generations.map((generation) => (
                <div
                  key={generation.generation_id}
                  className="flex items-center justify-between space-x-4 text-xs font-semibold capitalize leading-none"
                >
                  <span>
                    {t("organization.fields.generation-label")} {generation.generation}
                  </span>
                  <span>
                    {t("organization.fields.generation-upload")} {format(generation.upload_date, "PP", { locale })}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

function Simulations({ simulations }: Pick<OrganizationDetailType, "simulations">) {
  const { t, i18n } = useTranslation();
  const locale = getLocale(i18n.language as LanguageCode);
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">{t("organization.simulations.title")}</CardTitle>
      </CardHeader>
      <CardContent className="grid max-h-[600px] gap-6 overflow-y-auto">
        {simulations.length === 0 ? (
          <div className="py-6 text-center text-sm text-muted-foreground">{t("error.no-result")}</div>
        ) : null}
        {simulations.map((simulation) => (
          <div key={simulation.id} className="flex items-center justify-between space-x-4">
            <div className="flex flex-col gap-1">
              <AnchorLink
                to={"/app/simulations"}
                label={simulation.field.name}
                className="text-sm font-medium capitalize leading-none"
              />
              <p className="ml-4 flex flex-col gap-1 text-xs text-muted-foreground">
                <span>
                  {t("organization.simulations.updated")}: {format(simulation.updated_at, "PP", { locale })}
                </span>
                <span>
                  {t("organization.fields.generation-label")} {simulation.field.generation}
                </span>
              </p>
            </div>

            <div>
              <Badge variant={"outline"} className="capitalize">
                {simulation.status.toLowerCase()}
              </Badge>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

function Container({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <div className={cn("flex items-center justify-center [&>div]:w-full", className)} {...props} />;
}
