import { AlertCircle } from "lucide-react";

function FormError({ error }: { error?: string | null }) {
  return error ? (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3 text-sm">
          <div className="text-red-700">
            <p>{error}</p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default FormError;
