import Combobox from "@/components/combobox/combobox";
import { useFieldGenerationDetail } from "@/query/field.query";
import { useMemo } from "react";

interface Props {
  fieldId: string;
  fieldGeneration: number;
  parcelId?: string | null;
  onSelect: (parcelId: string | null) => void;
}

export function ParcelCombobox({ parcelId = null, onSelect, fieldId, fieldGeneration }: Props) {
  const { data } = useFieldGenerationDetail(fieldId, fieldGeneration);
  const { selectedParcel, parcels } = useMemo(() => {
    const parcels = data?.parcels ?? [];
    const selectedParcel = parcels.find((parcel) => parcel.id_parcel === parcelId) ?? null;
    return { selectedParcel, parcels };
  }, [data, parcelId]);

  return (
    <Combobox renderSelected={() => (selectedParcel ? selectedParcel.id_parcel : "Select parcel...")}>
      {parcels.map((parcel) => {
        const isSelected = parcel.id_parcel === parcelId;
        return (
          <Combobox.Item
            key={parcel.id_parcel}
            value={`${parcel.id_parcel} ${parcel.id_ilot} ${parcel.cult_ref}`}
            isSelected={isSelected}
            onSelect={() => onSelect(isSelected ? null : parcel.id_parcel)}
          >
            <div className="grid grid-cols-6 gap-4">
              <span className="col-span-2 min-w-[80px] font-semibold">{parcel.id_parcel}</span>
              <div className="col-span-2 hidden flex-row gap-2 sm:flex">
                <span className="whitespace-nowrap text-muted-foreground">Parcel plot:</span>
                <span className="min-w-[100px]">{parcel.id_ilot}</span>
              </div>
              {parcel.cult_ref ? (
                <div className="col-span-2 flex flex-row gap-2">
                  <span className="text-muted-foreground">Ref. Crop:</span>
                  <span className="capitalize">{parcel.cult_ref}</span>
                </div>
              ) : null}
            </div>
          </Combobox.Item>
        );
      })}
    </Combobox>
  );
}
