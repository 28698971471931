import { useTranslation } from "react-i18next";
import ErrorLayoutPage from "@/layout/ErrorLayoutPage";

function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <ErrorLayoutPage errorCode={404} title={t("error.notfound.title")} description={t("error.notfound.description")} />
  );
}

export default NotFoundPage;
