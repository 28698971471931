import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { OrganizationType } from "@/model/organization.typing";
import { isAdmin } from "@/model/user.typing";
import { useUserOrganizationList } from "@/query/organization.query";
import { useLoggedUser } from "@/query/user.query";
import { useCurrentOrganizationStore } from "@/store/useCurrentOrganizationStore";
import { cn } from "@/utils/utils";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { Loader2Icon } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<typeof PopoverTrigger>;

interface TeamSwitcherProps extends PopoverTriggerProps {}

export default function OrganizationSwitcher({ className }: TeamSwitcherProps) {
  const { t } = useTranslation();
  const { currentOrganization, setOrganization, init } = useCurrentOrganizationStore();
  const { data: user, isLoading } = useLoggedUser();
  const { data: organizations, isLoading: organizationIsLoading } = useUserOrganizationList();

  const [open, setOpen] = useState(false);

  const organizationList = useMemo(() => {
    if (isAdmin(user)) {
      return [{ id: null, name: t("organizations.all") }, ...(organizations ?? [])] as OrganizationType[];
    }
    return organizations ?? [];
  }, [organizations, user, t]);

  useEffect(() => {
    if (user !== undefined && user !== null && !organizationIsLoading) {
      init(user.id, organizationList);
    }
  }, [init, user, organizationList, organizationIsLoading]);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a team"
            className={cn("w-[200px] justify-between", className)}
          >
            {isLoading ? (
              <Loader2Icon className="h-4 w-4 shrink-0 animate-spin" />
            ) : (
              <>
                {currentOrganization?.name ?? ""}
                <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandEmpty>No organization found.</CommandEmpty>
              {organizationList.map((organization) => (
                <CommandItem
                  key={organization.id}
                  onSelect={() => {
                    setOrganization(organization);
                    setOpen(false);
                  }}
                  className="text-sm"
                >
                  {organization.name}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      currentOrganization?.id === organization.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
