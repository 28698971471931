import { PageContainer } from "@/components/PageContainer";
import AnchorLink from "@/components/ui/anchorLink";
import { Button } from "@/components/ui/button";
import { Card, CardHeader } from "@/components/ui/card";
import { FieldWithGenerationType } from "@/model/field.typing";
import { isAdmin } from "@/model/user.typing";
import { fieldListQuery } from "@/query/field.query";
import { useLoggedUser } from "@/query/user.query";
import { EditField } from "@/routes/field/EditField";
import { AddFieldDialog } from "@/routes/field/addFieldDialog";
import { AddFieldGenerationDialog } from "@/routes/field/addFieldGeneration";
import { useCurrentOrganizationStore } from "@/store/useCurrentOrganizationStore";
import { LanguageCode, getLocale } from "@/utils/locale";
import { useQuery } from "@tanstack/react-query";
import { format, formatRelative } from "date-fns";
import { LinkIcon, PlusIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function FieldsPage() {
  const { data: loggedUser } = useLoggedUser();
  const isUserAdmin = isAdmin(loggedUser);
  const { t, i18n } = useTranslation();
  const locale = getLocale(i18n.language as LanguageCode);
  const { currentOrganization } = useCurrentOrganizationStore();
  const { data = [] } = useQuery({ ...fieldListQuery(currentOrganization?.id) });
  const [editField, setEditField] = useState<null | FieldWithGenerationType>(null);
  const [fieldGeneration, setAddFieldGeneration] = useState<null | FieldWithGenerationType>(null);
  const [showAddDialog, setShowAddDialog] = useState(false);

  const title = `${currentOrganization?.name ?? ""} ${t("fields.title")}`.trim();

  return (
    <PageContainer
      title={title}
      description={t("fields.description")}
      actionsRender={() => {
        return isUserAdmin ? (
          <Button onClick={() => setShowAddDialog(true)}>
            <PlusIcon className="mr-2 h-5 w-5" />
            <span>{t("fields.create-field")}</span>
          </Button>
        ) : null;
      }}
    >
      {editField ? <EditField editField={editField} onClosed={() => setEditField(null)} /> : null}
      {fieldGeneration ? (
        <AddFieldGenerationDialog field={fieldGeneration} toggleDialog={() => setAddFieldGeneration(null)} />
      ) : null}
      <AddFieldDialog isOpen={showAddDialog} toggleDialog={setShowAddDialog} />

      <div className="mt-6">
        {data.map((field) => (
          <Card key={field.id} className="mt-4">
            <CardHeader>
              <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
                <div className="flex-1 text-sm text-muted-foreground">
                  <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between">
                    <div className="flex flex-col gap-2">
                      <AnchorLink
                        to={`/app/case-study/${field.id}`}
                        label={
                          <div className="flex flex-nowrap items-center gap-2">
                            <LinkIcon className="h-4 w-4 text-primary" />
                            <span>{field.name}</span>
                          </div>
                        }
                        className="text-xl leading-4"
                      />
                      <span className="text-muted-foreground">{field.description}</span>
                    </div>
                    <div>
                      {isUserAdmin ? (
                        <Button variant={"outline"} size="sm" onClick={() => setEditField(field)}>
                          {t("fields.edit-field")}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="mt-2 grid grid-cols-1 items-center gap-x-4 gap-y-2 md:grid-cols-4">
                      <div className="flex items-center gap-1">
                        <span>{t("fields.labels.organization")}</span>
                        <AnchorLink
                          to={`/app/organizations/${field.organization.id}`}
                          label={field.organization.name}
                          className="font-semibold"
                        />
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="whitespace-nowrap">{t("fields.labels.generations")}</span>
                        <span className="text-sm font-semibold text-primary">{field.generations.length}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span>{t("fields.labels.updated")}</span>
                        <span
                          className="whitespace-nowrap text-sm font-semibold capitalize text-primary"
                          title={format(field.updated_at, "PPp", { locale })}
                        >
                          {formatRelative(field.updated_at, new Date(), { locale })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
        ))}
      </div>
    </PageContainer>
  );
}

export default FieldsPage;
