import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/utils/utils";
import { Link, LinkProps } from "react-router-dom";

interface Props extends LinkProps {
  label: string | React.ReactNode;
}

function AnchorLink({ label, className = "", ...props }: Props) {
  return (
    <Link {...props} className={cn(buttonVariants({ variant: "link", className: `${className} h-auto p-0` }))}>
      {label}
    </Link>
  );
}

export default AnchorLink;
