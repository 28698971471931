import { z } from "zod";

const BasFieldSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable().optional(),
  updated_at: z.coerce.date(),
  organization: z.object({
    id: z.number(),
    name: z.string(),
  }),
});

const FieldGenerationSchema = z.object({
  generation_id: z.string(),
  generation: z.number(),
  upload_date: z.coerce.date(),
});

export const FieldWithGenerationsSchema = z
  .object({ generations: z.array(FieldGenerationSchema) })
  .merge(BasFieldSchema);

export const FieldGenerationsSchema = z.object({ generation: FieldGenerationSchema }).merge(BasFieldSchema);

export const FieldSchema = BasFieldSchema.merge(FieldGenerationSchema);

export const FieldGenerationParcelSchema = z.object({
  id_parcel: z.string(),
  id_ilot: z.string(),
  cult_ref: z.string().optional(),
  sequence: z.array(z.string()),
  geometry: z.object({
    type: z.string(),
    coordinates: z
      .array(z.array(z.array(z.number())))
      .optional()
      .default([]),
  }),
});

export const FieldGenerationInfoSchema = z.object({
  min_year: z.number(),
  max_year: z.number(),
  pedo_zones: z.array(z.string()),
  cultures: z.array(z.string()),
  climate_scenarios: z.array(z.string()),
  parcels_count: z.number(),
  parcels: z.array(FieldGenerationParcelSchema),
});

export type FieldType = z.infer<typeof FieldSchema>;
export type FieldWithGenerationType = z.infer<typeof FieldWithGenerationsSchema>;
export type FieldGenerationType = z.infer<typeof FieldGenerationsSchema>;
export type FieldBaseGenerationType = z.infer<typeof FieldGenerationSchema>;
export type FieldGenerationParcel = z.infer<typeof FieldGenerationParcelSchema>;
export type FieldGenerationInfo = z.infer<typeof FieldGenerationInfoSchema>;
