import FormError from "@/components/form/formError";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { FieldWithGenerationType } from "@/model/field.typing";
import { useCreateFieldGenerationMutation } from "@/query/field.query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UploadFieldFile } from "./uploadFieldFile";

interface Props {
  field: FieldWithGenerationType;
  toggleDialog: (isOpen: boolean) => void;
}

export function AddFieldGenerationDialog({ toggleDialog, field }: Props) {
  const { t } = useTranslation();
  const { form, onSubmit, i18nErrorMessage, onReset, isLoading, isSuccess } = useCreateFieldGenerationMutation(
    field.id
  );
  useEffect(() => {
    if (isSuccess) {
      toggleDialog(false);
      onReset();
    }
  }, [isSuccess, toggleDialog, onReset]);

  return (
    <Dialog open={true} onOpenChange={toggleDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{field.name}</DialogTitle>
          <DialogDescription>{t("generation-upload.description")}</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
            <div className="space-y-6 py-2 pb-4">
              <FormField
                control={form.control}
                name="file"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("generation-upload.label")}</FormLabel>
                    <div>
                      <UploadFieldFile
                        onFileRejection={(rejected) => {
                          form.setError("file", rejected.errors[0]);
                        }}
                        onFileSelection={(file) => {
                          field.onChange(file);
                        }}
                      />
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
            </div>

            <DialogFooter>
              <Button variant="outline" onClick={() => toggleDialog(false)}>
                {t("generation-upload.cancel")}
              </Button>
              <Button type="submit" disabled={isLoading}>
                {t("generation-upload.save")}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
