import { useValidateZipFile } from "@/hooks/validation";
import { bytesToSize } from "@/utils/format";
import { UploadIcon } from "lucide-react";
import { FileRejection, useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  onFileSelection: (file: File) => void;
  onFileRejection: (fileRejection: FileRejection) => void;
}

const maxSize = 52_000_000; // 50 MB

export function UploadFieldFile({ onFileSelection, onFileRejection }: Props) {
  const { t } = useTranslation();
  const validateFile = useValidateZipFile();

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDropRejected: (rejections) => onFileRejection(rejections[0]),
    onDropAccepted: (files) => onFileSelection(files[0]),
    validator: validateFile,
    multiple: false,
    maxSize,
  });

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div
          className="flex h-56 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-800"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center justify-center pb-6 pt-5">
            <UploadIcon className="h-10 w-10 text-gray-500 dark:text-gray-400" />
            {isDragActive ? (
              <p className="mb-2 text-sm text-secondary-foreground">{t("generation-upload.upload-message-active")}</p>
            ) : (
              <>
                <p className="mb-2 text-sm text-secondary-foreground">
                  <Trans
                    i18nKey="generation-upload.upload-message"
                    components={{
                      s: <span className="font-semibold" />,
                    }}
                  />
                </p>
                <p className="text-xs text-secondary-foreground">ZIP (MAX. {bytesToSize(maxSize)})</p>
              </>
            )}
          </div>
        </div>
      </div>
      {acceptedFiles.length > 0 ? (
        <ul>
          {acceptedFiles.map((acceptedFile) => (
            <li key={acceptedFile.name} className="flex max-w-md items-baseline justify-between gap-4">
              <div className="flex-1 truncate">
                <span className="font-medium" title={acceptedFile.name}>
                  {acceptedFile.name}
                </span>
              </div>
              <div className="min-w-fit">{bytesToSize(acceptedFile.size)}</div>
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
}
