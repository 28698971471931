import FormError from "@/components/form/formError";
import FormInput from "@/components/form/formInput";
import Logo from "@/components/svgs/Logo";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import PageFooter from "@/layout/PageFooter";
import { useForgotPasswordMutation } from "@/query/user.query";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function ForgotPasswordPage() {
  const { t } = useTranslation();
  const { form, onSubmit, i18nErrorMessage, isSuccess, isLoading } = useForgotPasswordMutation();
  return (
    <main className="flex min-h-full">
      <div className="flex w-full flex-1 flex-col">
        <div className="flex h-full flex-col justify-between">
          <div className="flex h-full w-full flex-1 sm:items-center">
            <div className="w-full min-w-full">
              <div className="mx-auto sm:my-12 sm:max-w-lg">
                <Logo className="mx-auto w-72" />
                <div className="mt-8 px-4 sm:px-0">
                  {isSuccess ? (
                    <div className="my-4">
                      <div className="text-center">
                        <h2 className="text-3xl font-semibold tracking-tight text-secondary-foreground">
                          {t("forgot-password.title")}
                        </h2>
                      </div>
                      <div className="mt-8 text-center">
                        <h2 className="mb-4 font-semibold">
                          {t("forgot-password.sent-mail.line1", { email: form.getValues("email") })}
                        </h2>
                        <p> {t("forgot-password.sent-mail.line2")}</p>
                        <p className="mt-4 text-sm leading-7 text-muted-foreground">
                          {t("forgot-password.sent-mail.line3")}
                        </p>
                      </div>
                      <div className="mt-4 text-center">
                        <Button asChild variant="outline" type="button">
                          <Link to="/signin">{t("forgot-password.back-signin")}</Link>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Form {...form}>
                      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                      <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
                        <div className="text-center">
                          <h2 className="mb-3 text-3xl font-semibold tracking-tight text-secondary-foreground">
                            {t("forgot-password.title")}
                          </h2>
                          <h2 className="text-muted-foreground">{t("forgot-password.description")}</h2>
                        </div>
                        <FormField
                          control={form.control}
                          name="email"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <FormInput type="email" autoComplete="email" placeholder="Email" {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
                        <div className="grid grid-rows-2 gap-2 sm:grid-cols-2">
                          <Button asChild disabled={isLoading} variant="outline" type="button" className={"w-full"}>
                            <Link to="/signin">{t("forgot-password.back-signin")}</Link>
                          </Button>
                          <Button disabled={isLoading} variant="default" type="submit" className={"w-full"}>
                            {t("forgot-password.reset-password")}
                          </Button>
                        </div>
                      </form>
                    </Form>
                  )}
                </div>
              </div>
            </div>
          </div>
          <PageFooter />
        </div>
      </div>
    </main>
  );
}
