import { ButtonGroup } from "@/components/buttonGroup/buttonGroup";
import { SimulatorParams } from "@/model/simulator.typing";
import { WavesIcon } from "lucide-react";

const options = [
  { id: "O", label: "Irrigation" },
  { id: "N", label: "Pas d'irrigation" },
];

export function IrrigationFilter({
  selected,
  setSelected,
}: {
  selected: SimulatorParams["irrigation"];
  setSelected: (value: SimulatorParams["irrigation"]) => void;
}) {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="flex flex-nowrap items-center gap-2 text-sm font-semibold">
        <WavesIcon className="h-4 w-4" />
        Irrigation
      </h3>
      <ButtonGroup
        options={options}
        selected={selected}
        setSelected={(value) => setSelected(value as SimulatorParams["irrigation"])}
      />
    </div>
  );
}
