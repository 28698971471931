import api from "@/api/api";
import {
  FieldGenerationInfo,
  FieldGenerationInfoSchema,
  FieldGenerationType,
  FieldGenerationsSchema,
  FieldWithGenerationType,
  FieldWithGenerationsSchema,
} from "@/model/field.typing";
import { AxiosError } from "axios";
import { differenceInMilliseconds } from "date-fns";
import { z } from "zod";

export async function fetchFields(organizationId?: number, signal?: AbortSignal): Promise<FieldWithGenerationType[]> {
  const url =
    organizationId !== undefined && organizationId !== null
      ? `/v1/case-studies?organization_id=${organizationId}`
      : "/v1/case-studies";
  const response = await api.get<{ items: unknown }>(url, {
    signal,
  });
  return z.array(FieldWithGenerationsSchema).parse(response.data.items);
}

export async function fetchFieldGenerationInfo(
  fieldId: string,
  fieldGenerationId: number,
  signal?: AbortSignal
): Promise<FieldGenerationInfo> {
  const response = await api.get(`/v1/case-studies/${fieldId}/${fieldGenerationId}/information`, {
    signal,
  });
  return FieldGenerationInfoSchema.parse(response.data);
}

export async function fetchFieldGenerationById(
  fieldGenerationId?: string,
  signal?: AbortSignal
): Promise<FieldGenerationType | null> {
  const response = await api.get(`/v1/case-studies/generation/${fieldGenerationId}`, {
    signal,
  });
  if (response.data === null) {
    return null;
  }
  return FieldGenerationsSchema.parse(response.data);
}

export async function requestUpdateField(
  fieldId: string,
  body: { name: string; description: string | null | undefined },
  signal?: AbortSignal
): Promise<void> {
  await api.put<void>(
    `/v1/case-studies/${fieldId}`,
    {
      name: body.name,
      description: !!body.description && body.description.trim().length === 0 ? undefined : body.description,
    },
    { signal }
  );
}

export async function requestCreateField(
  body: {
    name: string;
    description: string;
    organizationId: number;
  },
  signal?: AbortSignal
) {
  const response = await api.post<{ fieldId: string }>(
    "/v1/case-studies",
    {
      name: body.name,
      description: !!body.description && body.description.trim().length === 0 ? undefined : body.description,
      organization_id: body.organizationId,
    },
    { signal }
  );
  return response.data.fieldId;
}

export const uploadFieldFile = async (
  fieldId: string,
  file: File,
  events?: {
    onUploading: (abort: AbortController) => void;
    onProgress: (progress: number, remainingInMilliseconds: number) => void;
    onUploaded: () => void;
    onFailed: (isCancellation: boolean) => void;
  }
): Promise<void> => {
  try {
    const abort = new AbortController();
    events?.onUploading(abort);
    const url = `/v1/case-studies/${fieldId}/upload?fileName=${encodeURIComponent(file.name)}`;
    const formData = new FormData();
    formData.append("file", file);
    const startTime = new Date();
    await api.post(url, formData, {
      onUploadProgress: (ev) => {
        const total = ev.total ?? 1;
        const diff = differenceInMilliseconds(startTime, new Date());
        const uploadSpeed = diff > 0 ? ev.loaded / diff : ev.loaded;
        const remainingInMilliseconds = uploadSpeed > 0 ? (total - ev.loaded) / uploadSpeed : total - ev.loaded;
        const progress = total > 0 ? Math.round((ev.loaded * 100) / total) : ev.loaded * 100;
        events?.onProgress(progress, remainingInMilliseconds);
      },
      signal: abort.signal,
    });
    events?.onUploaded();
  } catch (error) {
    const isCancellation = (error as AxiosError)?.code === "ERR_CANCELED";
    events?.onFailed(isCancellation);
  }
};
