import { Separator } from "@/components/ui/separator";
import { SimulatorParams, SimulatorType } from "@/model/simulator.typing";
import { simulatorListQuery } from "@/query/simulator.query";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { useDidMount } from "rooks";
import { ClimatFilter } from "./filters/climatFilter";
import { FertilisationFilter } from "./filters/fertilisationFilter";
import { IrrigationFilter } from "./filters/irrigationFilter";
import { LocationFilter } from "./filters/locationFilter";
import { RotationFilter } from "./filters/rotationFilter";
import { SolFilter } from "./filters/solFilter";
import { HeroSection } from "./heroSection";
import { RadarChart } from "./radar";

const defaultParams: SimulatorParams = {
  localisation: "Béarn",
  scenario_climatique: "RCP2.6",
  rotationFR: "Monoculture de maïs",
  irrigation: "N",
  sol: "Argilo-calcaire",
  type_fertilisation: "Organique", // "both" => "mineral" | "organic"
};
export function loader(queryClient: QueryClient) {
  return async () => {
    const query = simulatorListQuery(defaultParams);
    return queryClient.getQueryData<SimulatorType[]>(query.queryKey) ?? (await queryClient.fetchQuery(query));
  };
}

export function SimulatorPage() {
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;

  useDidMount(() => {
    document.documentElement.classList.remove("dark");
  });

  const { params, updateParams, data } = useFilters(initialData ?? []);
  return (
    <>
      <HeroSection />
      <Separator />
      <div id="simulation" className="flex h-full flex-row justify-center">
        <div className="grid grid-cols-1 items-stretch sm:grid-cols-2 sm:grid-rows-1">
          <div className="container flex flex-col gap-4 self-center">
            <LocationFilter
              selected={params.localisation}
              setSelected={(localisation) => updateParams({ localisation })}
            />
            <RotationFilter selected={params.rotationFR} setSelected={(rotationFR) => updateParams({ rotationFR })} />
            <IrrigationFilter selected={params.irrigation} setSelected={(irrigation) => updateParams({ irrigation })} />
            <SolFilter selected={params.sol} setSelected={(sol) => updateParams({ sol })} />
            <FertilisationFilter
              selected={params.type_fertilisation}
              setSelected={(type_fertilisation) =>
                updateParams({ type_fertilisation: type_fertilisation ?? undefined })
              }
            />
            <ClimatFilter
              selected={params.scenario_climatique}
              setSelected={(scenario_climatique) => updateParams({ scenario_climatique })}
            />
          </div>
          <div className="flex flex-col justify-center">
            <RadarChart simulationData={data[0]} />
          </div>
        </div>
      </div>
    </>
  );
}

function useFilters(initialData: SimulatorType[]) {
  const [params, setParams] = useState<SimulatorParams>(defaultParams);

  const { data } = useQuery({ ...simulatorListQuery(params), initialData });

  const updateParams = (newParams: Partial<SimulatorParams>) => {
    setParams((prev) => ({ ...prev, ...newParams }));
  };

  return { params, updateParams, data };
}
