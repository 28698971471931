import { useTranslation } from "react-i18next";
import ErrorLayoutPage from "@/layout/ErrorLayoutPage";

function ServerErrorPage() {
  const { t } = useTranslation();
  return (
    <ErrorLayoutPage errorCode={500} title={t("error.server.title")} description={t("error.server.description")} />
  );
}

export default ServerErrorPage;
