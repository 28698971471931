import { ButtonGroup } from "@/components/buttonGroup/buttonGroup";
import { SimulatorParams } from "@/model/simulator.typing";
import { ShrubIcon } from "lucide-react";

const options = [
  { id: "Minéral", label: "Minéral exclusif" },
  { id: "Organique", label: "Minéral et organique" },
];

export function FertilisationFilter({
  selected,
  setSelected,
}: {
  selected: SimulatorParams["type_fertilisation"];
  setSelected: (value: SimulatorParams["type_fertilisation"]) => void;
}) {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="flex flex-nowrap items-center gap-2 text-sm font-semibold">
        <ShrubIcon className="h-4 w-4" />
        Type de fertilisation
      </h3>
      <ButtonGroup
        options={options}
        selected={selected}
        setSelected={(value) => setSelected(value as SimulatorParams["type_fertilisation"])}
      />
    </div>
  );
}
