import { ButtonGroup } from "@/components/buttonGroup/buttonGroup";
import { SimulatorParams } from "@/model/simulator.typing";
import { SproutIcon } from "lucide-react";

const options = ["Argilo-calcaire", "Limoneux", "Limono-sableux"];

export function SolFilter({
  selected,
  setSelected,
}: {
  selected: SimulatorParams["sol"];
  setSelected: (value: SimulatorParams["sol"]) => void;
}) {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="flex flex-nowrap items-center gap-2 text-sm font-semibold">
        <SproutIcon className="h-4 w-4" />
        Sol
      </h3>
      <ButtonGroup
        options={options}
        selected={selected}
        setSelected={(value) => setSelected(value as SimulatorParams["sol"])}
      />
    </div>
  );
}
