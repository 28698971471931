import { Button } from "@/components/ui/button";
import { Illustration500, Illustrations404 } from "components/svgs/Illustrations";
import PageFooter from "layout/PageFooter";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  description: string;
  errorCode: 404 | 500;
}

function ErrorLayoutPage({ title, errorCode, description }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/app", { replace: true });
  };
  return (
    <>
      <div className="flex min-h-full flex-col pt-16">
        <main className="mx-auto flex w-full grow flex-col px-4 sm:justify-center sm:px-6 lg:px-8">
          <div className="flex flex-col items-center justify-center space-x-4 py-16 lg:flex-row">
            <div className="text-center">
              <p className="font-semibold uppercase tracking-wide">{t("error.error-code", { errorCode })}</p>
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-primary sm:text-5xl">{title}</h1>
              <p className="mt-2 text-muted-foreground">{description}</p>
              <div className="mt-6">
                <Button onClick={handleOnClick}>{t("error.goBackHome")}</Button>
              </div>
            </div>
            {errorCode === 404 && (
              <div className="order-first mb-16 hidden w-80 sm:block lg:order-last lg:w-full lg:max-w-md">
                <Illustrations404 />
              </div>
            )}

            {errorCode === 500 && (
              <div className="order-first mb-16 hidden w-96 sm:block lg:order-last lg:w-full lg:max-w-xl">
                <Illustration500 />
              </div>
            )}
          </div>
        </main>
        <PageFooter />
      </div>
    </>
  );
}

export default ErrorLayoutPage;
