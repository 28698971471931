import { enUS, fr } from "date-fns/locale";
import { FlagIconFR, FlagIconUS } from "@/components/svgs/Flags";

type Icon = typeof FlagIconUS;

export type LanguageCode = "en" | "fr";

export const LOCALES: Record<LanguageCode, Locale> = {
  en: enUS,
  fr: fr,
};

export const LANGUAGES: Array<{ id: LanguageCode; name: string; icon: Icon }> = [
  { id: "en", name: "English", icon: FlagIconUS },
  { id: "fr", name: "Francais", icon: FlagIconFR },
];

export const DEFAULT_LANGUAGE = LANGUAGES[0];

export function getLocale(language: LanguageCode): Locale {
  return LOCALES[language] ?? enUS;
}
