import {
  fetchFieldGenerationById,
  fetchFieldGenerationInfo,
  fetchFields,
  requestCreateField,
  requestUpdateField,
  uploadFieldFile,
} from "@/api/field.api";
import { useToast } from "@/components/ui/use-toast";
import { FieldType, FieldWithGenerationType } from "@/model/field.typing";
import { DEFAULT_QUERY_OPTIONS } from "@/providers/ReactQueryProvider";
import { getErrorMessage } from "@/utils/error";
import { toNumber } from "@/utils/mapping";
import { zodResolver } from "@hookform/resolvers/zod";
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export function fieldListQuery(organizationId?: number) {
  return {
    queryKey: ["fields", "list", organizationId],
    queryFn: async ({ signal }: QueryFunctionContext) => await fetchFields(organizationId, signal),
    ...DEFAULT_QUERY_OPTIONS,
  };
}

export function fielGenerationQuery(fieldGenerationId?: string) {
  return {
    queryKey: ["fields", "generation", fieldGenerationId],
    queryFn: async ({ signal }: QueryFunctionContext) => await fetchFieldGenerationById(fieldGenerationId, signal),
    ...DEFAULT_QUERY_OPTIONS,
  };
}

export function fieldListByOrganizationQuery(organizationId?: number) {
  return {
    queryKey: ["fields", "list", organizationId],
    queryFn: async ({ signal }: QueryFunctionContext) => await fetchFields(undefined, signal),
    ...DEFAULT_QUERY_OPTIONS,
    enable: !!organizationId,
  };
}

export function useFetchFieldDetail(fieldId: string | undefined, currentOrganizationId?: number) {
  const { data: fields = [], isLoading } = useQuery({ ...fieldListQuery(currentOrganizationId) });
  const field = useMemo(() => {
    return fields.find((f) => f.id === fieldId) ?? null;
  }, [fieldId, fields]);

  return { field, isLoading };
}

export function fieldGenerationDetailQuery(fieldId: string, fieldGeneration: number) {
  return {
    queryKey: ["generation", fieldId, fieldGeneration],
    queryFn: ({ signal }: QueryFunctionContext) => fetchFieldGenerationInfo(fieldId, fieldGeneration, signal),
  };
}

export function useFieldGenerationDetail(fieldId: string, fieldGeneration: number) {
  return useQuery(fieldGenerationDetailQuery(fieldId, fieldGeneration));
}

export function useUpdateFieldMutation(field: FieldWithGenerationType | FieldType) {
  const { toast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formSchema = z.object({
    name: z.string().trim().min(1, t("update-fields.name.required")),
    description: z.string(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: { name: field.name, description: field.description ?? "" },
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    reset,
    error: axiosError,
  } = useMutation<void, AxiosError, z.infer<typeof formSchema>>({
    mutationFn: ({ name, description }) => requestUpdateField(field.id, { name, description }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(["fields"]);
      await queryClient.refetchQueries(["fields"]);
      toast({ variant: "affirmative", title: "", description: t("update-fields.success") });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (values) => {
    return mutate(values);
  };

  useEffect(() => {
    if (field?.name) {
      form.setValue("name", field.name);
    }
  }, [form, field?.name]);

  const onReset = () => {
    reset();
    form.reset();
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), onReset, i18nErrorMessage, isLoading, isSuccess };
}

export function useCreateFieldMutation(field?: FieldWithGenerationType | FieldType | null | undefined) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formSchema = z.object({
    name: z.string().trim().min(1, t("create-fields.name.required")),
    description: z.string(),
    organizationId: z.preprocess(
      toNumber,
      z.number({ errorMap: () => ({ message: t("form-fields.organization.required") }) })
    ),
    file: z
      .instanceof(File, { message: t("error.file-required") })
      .refine((file) => file.size > 0, { message: t("error.file-required") }),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: { name: "", description: "" },
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    reset,
    error: axiosError,
  } = useMutation<void, AxiosError, z.infer<typeof formSchema>>({
    mutationFn: async ({ name, organizationId, description, file }) => {
      if (field?.id === null || field?.id === undefined) {
        const fieldId = await requestCreateField({ name, organizationId, description });
        await uploadFieldFile(fieldId, file);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["fields"]);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (values) => mutate(values);

  const onReset = () => {
    reset();
    form.reset();
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), onReset, i18nErrorMessage, isLoading, isSuccess };
}

export function useCreateFieldGenerationMutation(fieldId: string) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formSchema = z.object({
    file: z
      .instanceof(File, { message: t("error.file-required") })
      .refine((file) => file.size > 0, { message: t("error.file-required") }),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: {},
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    reset,
    error: axiosError,
  } = useMutation<void, AxiosError, z.infer<typeof formSchema>>({
    mutationFn: async ({ file }) => {
      await uploadFieldFile(fieldId, file);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["fields"]);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (values) => mutate(values);

  const onReset = () => {
    reset();
    form.reset();
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), onReset, i18nErrorMessage, isLoading, isSuccess };
}
