import {
  fetchLoggedUser,
  fetchUser,
  fetchUsers,
  requestActivateAccount,
  requestCreateUser,
  requestForgotPassword,
  requestResetPassword,
  requestSignIn,
  requestSignOut,
  requestUpdateUser,
} from "@/api/user.api";
import { useToast } from "@/components/ui/use-toast";
import { LoggedUser, UnloggedUser, User } from "@/model/user.typing";
import { DEFAULT_QUERY_OPTIONS } from "@/providers/ReactQueryProvider";
import { getErrorMessage } from "@/utils/error";
import { toNumber } from "@/utils/mapping";
import { redirectToUrl } from "@/utils/url";
import { zodResolver } from "@hookform/resolvers/zod";
import { QueryClient, QueryFunctionContext, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UseQueryOptions } from "@tanstack/react-query/src/types";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const PASSWORD_VALIDATION_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]+$/;

export function useSignInMutation() {
  const { t } = useTranslation();
  const formSchema = z.object({
    email: z
      .string()
      .trim()
      .min(1, { message: t("form-fields.email.valid") })
      .email({ message: t("form-fields.email.required") }),
    password: z
      .string()
      .trim()
      .min(1, { message: t("login.password.required") }),
    rememberMe: z.boolean().optional().default(false),
    redirectTo: z.string().optional(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { email: "", password: "", rememberMe: false },
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    error: axiosError,
  } = useMutation<void, AxiosError, z.infer<typeof formSchema>>({
    mutationFn: (form) => requestSignIn(form.email, form.password, form.rememberMe),
    onError: (error) => {
      console.log(error);
    },
  });

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  return { form, onSubmit: form.handleSubmit((form) => mutate(form)), i18nErrorMessage, isLoading, isSuccess };
}

export function userQuery(userId: number) {
  return {
    queryKey: ["users", userId],
    queryFn: async ({ signal }: QueryFunctionContext) => await fetchUser(userId, signal),
    ...DEFAULT_QUERY_OPTIONS,
  };
}

export function useUser(userId: number | undefined) {
  return useQuery<User>({ ...userQuery(userId as number), enabled: !!userId });
}

export const loggedUserQuery: UseQueryOptions<LoggedUser | null> = {
  ...DEFAULT_QUERY_OPTIONS,
  queryKey: ["user"],
  queryFn: () => fetchLoggedUser(),
  refetchOnWindowFocus: true,
  refetchOnReconnect: true,
  retry: false,
  staleTime: 10000, // 10 seconds
};

export async function executeLoggedUserQuery(queryClient: QueryClient) {
  return (
    queryClient.getQueryData<LoggedUser | null>(loggedUserQuery.queryKey as string[]) ??
    (await queryClient.fetchQuery<LoggedUser | null>(loggedUserQuery))
  );
}

export const useLoggedUser = () => useQuery({ ...loggedUserQuery });

export const useForgotPasswordMutation = () => {
  const { t } = useTranslation();
  const formSchema = z.object({
    email: z.string().trim().min(1, t("form-fields.email.required")).email(t("form-fields.email.valid")),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { email: "" },
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    error: axiosError,
  } = useMutation<
    void,
    AxiosError,
    {
      email: string;
    }
  >({
    mutationFn: ({ email }) => requestForgotPassword(email),
    onError: (error) => {
      console.log(error);
    },
  });

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = ({ email }) => {
    mutate({ email });
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), i18nErrorMessage, isLoading, isSuccess };
};

export const useResetPasswordMutation = (
  tokenRecord: {
    uuid: string;
    token: string;
  } | null
) => {
  const { t } = useTranslation();
  const formSchema = z.object({
    password: z
      .string()
      .min(8, t("form-fields.password.required"))
      .regex(PASSWORD_VALIDATION_REGEX, t("form-fields.password.valid")),
    uuid: z.string(),
    token: z.string(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: { password: "", uuid: tokenRecord?.uuid ?? "", token: tokenRecord?.token ?? "" },
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    error: axiosError,
  } = useMutation<void, AxiosError, z.infer<typeof formSchema>>({
    mutationFn: ({ password, uuid, token }) => {
      return requestResetPassword({ uuid, token, password });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (tokenRecord?.token) {
      form.setValue("token", tokenRecord.token);
    }
    if (tokenRecord?.uuid) {
      form.setValue("uuid", tokenRecord.uuid);
    }
  }, [form, tokenRecord]);

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = ({ password, uuid, token }) => {
    mutate({ password, uuid, token });
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), i18nErrorMessage, isLoading, isSuccess };
};

export function userListQuery() {
  return {
    queryKey: ["users", "list"],
    queryFn: async ({ signal }: QueryFunctionContext) => await fetchUsers(signal),
    ...DEFAULT_QUERY_OPTIONS,
  };
}

export const useActivateAccountMutation = (
  tokenRecord: {
    uuid: string;
    token: string;
    user: UnloggedUser;
  } | null
) => {
  const { t } = useTranslation();
  const formSchema = z.object({
    password: z
      .string()
      .min(8, t("form-fields.password.required"))
      .regex(PASSWORD_VALIDATION_REGEX, t("form-fields.password.valid")),
    fullname: z.string().min(1, { message: t("activate-account.fullname.required") }),
    uuid: z.string(),
    token: z.string(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: {
      password: "",
      uuid: tokenRecord?.uuid ?? "",
      token: tokenRecord?.token ?? "",
      fullname: tokenRecord?.user?.fullname ?? "",
    },
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    error: axiosError,
  } = useMutation<void, AxiosError, z.infer<typeof formSchema>>({
    mutationFn: ({ password, uuid, token }) => {
      return requestActivateAccount({ uuid, token, password });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (tokenRecord?.token) {
      form.setValue("token", tokenRecord.token);
    }
    if (tokenRecord?.uuid) {
      form.setValue("uuid", tokenRecord.uuid);
    }
    if (tokenRecord?.uuid) {
      form.setValue("fullname", tokenRecord.user.fullname);
    }
  }, [form, tokenRecord]);

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (values) => {
    mutate(values);
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), i18nErrorMessage, isLoading, isSuccess };
};

export const useLogoutMutation = (redirectTo?: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<void>({
    mutationFn: () => requestSignOut(),
    onSuccess: () => {
      queryClient.removeQueries(); // remove all queries
      const url = redirectToUrl(redirectTo);
      navigate(url);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export function useCreateUserMutation() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formSchema = z.object({
    email: z.string().trim().min(1, t("form-fields.email.required")).email(t("form-fields.email.valid")),
    fullname: z.string().min(1, { message: t("form-fields.fullname.required") }),
    organizationIds: z
      .array(
        z.preprocess(toNumber, z.number({ errorMap: () => ({ message: t("form-fields.organization.required") }) }))
      )
      .min(1, { message: t("form-fields.organization.required") }),
    isAdmin: z.boolean(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: { email: "", fullname: "", isAdmin: false, organizationIds: [] },
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    reset,
    error: axiosError,
  } = useMutation<void, AxiosError, z.infer<typeof formSchema>>({
    mutationFn: ({ email, fullname, organizationIds, isAdmin }) => {
      return requestCreateUser({ email, fullname, organizationIds, isAdmin });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["users"]);
      await queryClient.invalidateQueries(["organizations"]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (values) => mutate(values);

  const onReset = () => {
    reset();
    form.reset();
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), onReset, i18nErrorMessage, isLoading, isSuccess };
}

export function useUpdateUserMutation(user: User) {
  const { toast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formSchema = z.object({
    fullname: z.string().min(1, { message: t("form-fields.fullname.required") }),
    organizationIds: z
      .array(
        z.preprocess(toNumber, z.number({ errorMap: () => ({ message: t("form-fields.organization.required") }) }))
      )
      .min(1, { message: t("form-fields.organization.required") }),
    isAdmin: z.boolean(),
    isActive: z.boolean(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: {
      fullname: user.fullname,
      isAdmin: user.profile.role === "ADMIN",
      isActive: user.activated,
      organizationIds: user.profile.organizations.map((o) => o.id),
    },
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    error: axiosError,
  } = useMutation<void, AxiosError, z.infer<typeof formSchema>>({
    mutationFn: ({ fullname, organizationIds, isAdmin, isActive }) => {
      return requestUpdateUser(user.id, { fullname, isAdmin, activated: isActive, organizationIds });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["users"]);
      await queryClient.invalidateQueries(["organizations"]);
      toast({ id: "update-user", variant: "affirmative", description: t("edit-user.success") });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (values) => mutate(values);

  return { form, onSubmit: form.handleSubmit(onSubmit), i18nErrorMessage, isLoading, isSuccess };
}
