import { create } from "zustand";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";
import { changeLanguage } from "i18next";
import { DEFAULT_LANGUAGE, LanguageCode, LANGUAGES } from "@/utils/locale";

interface LanguageStore {
  languages: typeof LANGUAGES;
  language: (typeof LANGUAGES)[0];
  load: () => void;
  setLanguage: (languageCode: LanguageCode) => void;
}

export const useLanguageStore = create<LanguageStore>((setState, getState) => {
  function getLanguageFromSystem(): LanguageCode | null {
    const supportedLanguages = getState().languages.map(({ id }) => id);
    const languages = navigator.languages.map((lang) => lang.split("-")[0]);
    const detectedLanguage = languages.find((lang) => supportedLanguages.includes(lang as LanguageCode));
    return detectedLanguage ? (detectedLanguage as LanguageCode) : null;
  }

  return {
    languages: LANGUAGES,
    language: DEFAULT_LANGUAGE,
    load: () => {
      const detectedLanguage =
        getLocalStorageValue<LanguageCode>("maelia-language-key", "Read language") ??
        getLanguageFromSystem() ??
        DEFAULT_LANGUAGE.id;
      getState().setLanguage(detectedLanguage);
    },
    setLanguage: (languageCode: LanguageCode) => {
      const language = getState().languages.find(({ id }) => id === languageCode) ?? DEFAULT_LANGUAGE;
      setState((state) => ({ ...state, language }));
      void changeLanguage(language.id).then().catch();
      setLocalStorageValue("maelia-language-key", language.id, "Set language");
      try {
        if (document.documentElement.getAttribute("lang") !== language.id) {
          document.documentElement.setAttribute("lang", language.id);
        }
      } catch (ignore) {
        // ignore exception
      }
    },
  };
});
