import { PageContainer } from "@/components/PageContainer";
import { DataTable } from "@/components/table/dataTable";
import AnchorLink from "@/components/ui/anchorLink";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { OrganizationType } from "@/model/organization.typing";
import { organizationListQuery } from "@/query/organization.query";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, PlusIcon } from "lucide-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLoaderData, useNavigate } from "react-router-dom";

export function loader(queryClient: QueryClient) {
  return async () => {
    const query = organizationListQuery();
    return (
      queryClient.getQueryData<OrganizationType[]>(query.queryKey) ??
      (await queryClient.fetchQuery<OrganizationType[]>(query))
    );
  };
}

function OrganizationsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;
  const { data } = useQuery({ ...organizationListQuery(), initialData });

  const columns: ColumnDef<OrganizationType>[] = useMemo(() => {
    return [
      {
        id: t("organizations.column.name"),
        accessorKey: "name",
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("organizations.column.name")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <AnchorLink
            to={`/app/admin/organizations/${row.original.id}`}
            className="capitalize"
            label={row.original.name}
          />
        ),
      },
      {
        id: t("organizations.column.users"),
        accessorKey: "userCount",
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("organizations.column.users")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          if (row.original.user_count === 0) {
            return <div>-</div>;
          }
          return (
            <AnchorLink
              className="capitalize"
              label={`(${row.original.user_count}) ${t("organizations.column.users")}`}
              to={`/app/admin/users`}
            />
          );
        },
      },
      {
        id: t("organizations.column.fields"),
        accessorKey: "fieldCount",
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("organizations.column.fields")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          if (row.original.case_studies_count === 0) {
            return <div>-</div>;
          }
          return (
            <AnchorLink
              className="capitalize"
              label={`(${row.original.case_studies_count}) ${t("organizations.column.fields")}`}
              to={`/app/case-study`}
            />
          );
        },
      },
      {
        id: t("organizations.column.simulations"),
        accessorKey: "simulationCount",
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("organizations.column.simulations")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          if (row.original.simulation_count === 0) {
            return <div>-</div>;
          }
          return (
            <AnchorLink
              className="capitalize"
              label={`(${row.original.simulation_count}) ${t("organizations.column.simulations")}`}
              to={`/app/case-study`}
            />
          );
        },
      },
      {
        id: "actions",
        enableHiding: false,
        cell: ({ row }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">{t("organizations.action-menu.open")}</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => navigate(`/app/admin/organizations/${row.original.id}`)}>
                  {t("organizations.action-menu.details")}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate(`/app/admin/organizations/edit/${row.original.id}`)}>
                  {t("organizations.action-menu.edit")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ];
  }, [navigate, t]);

  return (
    <PageContainer
      title={t("organizations.title")}
      description={t("organizations.description")}
      actionsRender={() => {
        return (
          <Button asChild>
            <Link to="/app/admin/organizations/add" className="flex flex-nowrap items-center space-x-1">
              <PlusIcon className="h-5 w-5" />
              <span>{t("organizations.create-user")}</span>
            </Link>
          </Button>
        );
      }}
    >
      <DataTable withSelectColumn={true} data={data} columns={columns} />
    </PageContainer>
  );
}

export default OrganizationsPage;
