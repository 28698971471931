import { fetchSimulations, requestCreateSimulation, requestDownLoadLink } from "@/api/simulation.api";
import { toast } from "@/components/ui/use-toast";
import { FieldGenerationInfo } from "@/model/field.typing";
import { DEFAULT_QUERY_OPTIONS } from "@/providers/ReactQueryProvider";
import { getErrorMessage } from "@/utils/error";
import { zodResolver } from "@hookform/resolvers/zod";
import { QueryFunctionContext, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export function simulationListQuery(organizationId?: number) {
  return {
    queryKey: ["simulations", "list", organizationId],
    queryFn: async ({ signal }: QueryFunctionContext) => await fetchSimulations(organizationId, signal),
    ...DEFAULT_QUERY_OPTIONS,
    enable: !!organizationId,
  };
}

const CreateSimulationFormSchema = z.object({
  start_year: z.coerce.number(),
  years_of_simulation: z.coerce.number(),
  rpg_reference_year: z.coerce.number().default(2015),
  plant_growth_model: z.enum(["AqYieldNC", "AqYield"], {
    required_error: "You need to plant growth model.",
  }),
  meadow_growth_model: z.enum(["HerbSimHOR", "HerbSim"], {
    required_error: "You need to meadow growth model.",
  }),
  execute_hydro_model: z.boolean().default(false).optional(),
  executerOneParcel: z.boolean().default(false),
  climate_stress : z.boolean().default(false),
  rearing : z.boolean().default(false),
  climate_scenario_name : z.string().optional(),
  selectOperations: z.boolean().default(false),
  technical_operations: z.array(z.string()).optional(),
  id_parcel: z.string().optional(),
  outputs_hydro: z.boolean().default(false),
  outputs_nitrogen: z.boolean().default(false),
  outputs_carbon: z.boolean().default(false),
});

export type CreateSimulationForm = z.infer<typeof CreateSimulationFormSchema>;

export function useCreateSimulationMutation(
  organizationId: number,
  fieldId: string,
  fieldGeneration: number,
  fieldGenerationInfo: FieldGenerationInfo,
  parcelId?: string | null
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const maxNumberOfYears = fieldGenerationInfo.max_year - fieldGenerationInfo.min_year + 1;

  const queryClient = useQueryClient();

  const form = useForm<CreateSimulationForm>({
    resolver: zodResolver(CreateSimulationFormSchema),
    mode: "all",
    values: {
      start_year: fieldGenerationInfo.min_year,
      years_of_simulation: maxNumberOfYears,
      rpg_reference_year: 2015,
      plant_growth_model: "AqYieldNC",
      meadow_growth_model: "HerbSim",
      execute_hydro_model: false,
      climate_stress: false,
      rearing: false,
      climate_scenario_name: undefined,
      selectOperations: false,
      technical_operations: undefined,
      executerOneParcel: !!parcelId,
      id_parcel: parcelId || "",
      outputs_hydro: false,
      outputs_nitrogen: false,
      outputs_carbon: false,
    },
  });
  const {
    mutate,
    isSuccess,
    isLoading,
    reset,
    error: axiosError,
  } = useMutation<string, AxiosError, CreateSimulationForm>({
    mutationFn: async (form) => {
      return await requestCreateSimulation(organizationId, fieldId, fieldGeneration, form);
    },
    onSuccess: async (simulationId: string) => {
      await queryClient.invalidateQueries(["fields"]);
      await queryClient.invalidateQueries(["simulations"]);
      toast({ variant: "affirmative", title: "", description: t("add-simulation.success") });
      navigate(`/app/simulations/resutl/${fieldId}/${fieldGeneration}/${organizationId}/${simulationId}`);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<CreateSimulationForm> = (values) => mutate(values);

  const onReset = () => {
    reset();
    form.reset();
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), onReset, i18nErrorMessage, isLoading, isSuccess };
}

export function useGenerateDownloadLink() {
  const { mutate, isLoading } = useMutation<string, void, { organizationId: number; simulationId: string }>({
    mutationFn: ({ organizationId, simulationId }) => requestDownLoadLink(organizationId, simulationId),
    onSuccess: (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    },
  });

  return { mutate, isLoading };
}
