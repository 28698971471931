import FullPageLoader from "@/components/FullPageLoader";
import LogoutTimer from "@/components/LogoutTimer";
import { isAdmin } from "@/model/user.typing";
import { executeLoggedUserQuery, loggedUserQuery } from "@/query/user.query";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { LoaderFunctionArgs, Navigate, useLoaderData, useLocation } from "react-router-dom";
import MainLayout from "routes/layout/mainLayout";
import { redirectToLogin } from "utils/url";

export function loader(queryClient: QueryClient) {
  return async ({ request }: LoaderFunctionArgs) => {
    const user = await executeLoggedUserQuery(queryClient);
    if (!user) {
      throw redirectToLogin(new URL(request.url).pathname);
    }
    return user;
  };
}

export default function AuthRootLayout() {
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;
  const { data: user, isLoading } = useQuery({ ...loggedUserQuery, initialData });
  const location = useLocation();

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!user) {
    return <Navigate to="/signin" state={{ from: location.pathname }} replace />;
  }

  return (
    <>
      <MainLayout />
      {isAdmin(user) ? <LogoutTimer /> : null}
    </>
  );
}
