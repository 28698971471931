import { use } from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./en/translation.json";
import translationFr from "./fr/translation.json";
import { format as formatDate } from "date-fns";
import { getLocale, LanguageCode } from "@/utils/locale";

const resources = {
  en: { translation: translationEn as object },
  fr: { translation: translationFr as object },
};

void use(initReactI18next).init({
  lng: "en", // DEFAULT_LANGUAGE,
  supportedLngs: ["en", "fr"], // SUPPORTED_LANGUAGES_KEYS,
  defaultNS: "translation",
  ns: ["translation", "error", "enum"],
  nonExplicitSupportedLngs: true, // Allows "en-US" and "en-UK" to be implicitly supported as "en"
  fallbackLng: "fr",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value: unknown, format, lng): string => {
      if (value === null || value === undefined) {
        return "-";
      }
      if (format === "number" && typeof value === "number") {
        return value.toLocaleString(lng);
      }
      if (typeof value === "string") {
        const date = new Date(value);
        if (value && !isNaN(date.getTime())) {
          const locale = getLocale(lng as LanguageCode);
          return formatDate(date, format === "mediumDateTime" ? "PPpp" : "P", { locale });
        }
        return value;
      }
      return value as string;
    },
  },
  resources,
});
