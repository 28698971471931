import { useThemeStore } from "store/useThemeStore";
import { Moon, Sun } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";

function ThemeSwitch() {
  const { themes, theme, setTheme } = useThemeStore((state) => ({
    themes: state.themes,
    theme: state.theme,
    setTheme: state.setTheme,
  }));

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" role="menu">
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {themes.map(({ id, icon: Icon }) => (
          <DropdownMenuCheckboxItem key={id} checked={theme === id} onClick={() => setTheme(id)}>
            <div className="flex items-center space-x-1">
              <Icon size={16} />
              <span className="text-sm capitalize">{id}</span>
            </div>
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default ThemeSwitch;
