import FormError from "@/components/form/formError";
import FormInput from "@/components/form/formInput";
import OrganizationMultiSelect from "@/components/organization/organizationMultiSelect";
import { PageContainer } from "@/components/PageContainer";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import AnchorLink from "@/components/ui/anchorLink";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { userQuery, useUpdateUserMutation } from "@/query/user.query";
import { toNumber } from "@/utils/mapping";
import { QueryClient } from "@tanstack/react-query";
import { AlertTriangleIcon, ArrowLeft, SendIcon } from "lucide-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, LoaderFunctionArgs, redirect, useLoaderData, useNavigate } from "react-router-dom";

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) => {
    const id = toNumber(params["id"]);
    if (id === null) {
      throw redirect("/error/404");
    }
    const user = await queryClient.fetchQuery(userQuery(id));
    if (!user) {
      throw redirect("/error/404");
    }
    return { user };
  };
}

function EditUserPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;
  const { form, onSubmit, i18nErrorMessage, isLoading, isSuccess } = useUpdateUserMutation(user);

  useEffect(() => {
    if (isSuccess) {
      navigate("/app/admin/users");
    }
  }, [isSuccess, navigate]);

  return (
    <PageContainer
      title={user.fullname}
      description={t("edit-user.description")}
      actionsRender={() => {
        return (
          <Button asChild variant="outline">
            <Link to="/app/admin/users" className="flex flex-nowrap items-center space-x-1">
              <ArrowLeft className="h-5 w-5" />
              <span>{t("edit-user.back-to-users")}</span>
            </Link>
          </Button>
        );
      }}
    >
      <div className="mt-6 max-w-lg">
        <Form {...form}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
            <FormField
              control={form.control}
              name="organizationIds"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form-fields.organization.label")}</FormLabel>
                  <div>
                    <OrganizationMultiSelect
                      onSelect={(organizations) => field.onChange(organizations.map((o) => o.id))}
                      selectedIds={field.value}
                    />
                  </div>
                  <FormDescription className="inline-flex items-center gap-1">
                    <span>{t("create-user.organization.hint")}</span>
                    <AnchorLink
                      to={"/app/admin/organizations"}
                      label={t("create-user.organization.hint-link")}
                      className="text-[0.8rem]"
                    />
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="fullname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form-fields.fullname.label")}</FormLabel>
                  <FormControl>
                    <FormInput
                      type="text"
                      autoComplete="name"
                      placeholder={t("form-fields.fullname.placeholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div>
              <Label htmlFor="email">Email</Label>
              <FormInput id="email" readOnly={true} disabled={true} defaultValue={user.email} className="mb-1 mt-2" />
              <p className={"text-[0.8rem] text-muted-foreground"}>{t("edit-user.email.hint")}</p>
            </div>

            <div>
              <FormField
                control={form.control}
                name="isAdmin"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">{t("edit-user.isAdmin.label")}</FormLabel>
                      <FormDescription>{t("edit-user.isAdmin.hint")}</FormDescription>
                    </div>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                  </FormItem>
                )}
              />
              {form.getValues("isAdmin") === true ? (
                <Alert variant="warning" className="mt-2">
                  <AlertTriangleIcon className="h-4 w-4" />
                  <AlertTitle>{t("edit-user.isAdmin.warning.title")}</AlertTitle>
                  <AlertDescription>{t("edit-user.isAdmin.warning.description")}</AlertDescription>
                </Alert>
              ) : null}
            </div>

            <div>
              <FormField
                control={form.control}
                name="isActive"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">{t("edit-user.isActive.label")}</FormLabel>
                      <FormDescription>{t("edit-user.isActive.hint")}</FormDescription>
                    </div>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                  </FormItem>
                )}
              />
              {form.getValues("isActive") === true && !user.activated ? (
                <Alert variant="warning" className="mt-2">
                  <SendIcon className="h-4 w-4" />
                  <AlertTitle>{t("edit-user.isActive.warning.title")}</AlertTitle>
                  <AlertDescription>{t("edit-user.isActive.warning.description")}</AlertDescription>
                </Alert>
              ) : null}
            </div>

            {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
            <div className="grid grid-rows-2 gap-2 sm:grid-cols-2">
              <Button disabled={isLoading} variant="default" type="submit" className={"w-full"}>
                {t("edit-user.update")}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </PageContainer>
  );
}

export default EditUserPage;
