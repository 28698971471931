import { ButtonGroup } from "@/components/buttonGroup/buttonGroup";
import { SimulatorParams } from "@/model/simulator.typing";
import { CloudIcon } from "lucide-react";

const options = [
  { id: "RCP2.6", label: "RCP 2.6" },
  { id: "RCP4.5", label: "RCP 4.5" },
  { id: "RCP8.5", label: "RCP 8.5" },
];

export function ClimatFilter({
  selected,
  setSelected,
}: {
  selected: SimulatorParams["scenario_climatique"];
  setSelected: (value: SimulatorParams["scenario_climatique"]) => void;
}) {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="flex flex-nowrap items-center gap-2 text-sm font-semibold">
        <CloudIcon className="h-4 w-4" />
        Scénario climatique
      </h3>
      <ButtonGroup
        options={options}
        selected={selected}
        setSelected={(value) => setSelected(value as SimulatorParams["scenario_climatique"])}
      />
    </div>
  );
}
