import { cn } from "@/utils/utils";
import { useFormField } from "components/ui/form";
import { Input } from "components/ui/input";
import { AlertCircle } from "lucide-react";
import { forwardRef, InputHTMLAttributes, ReactNode } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  renderIcon?: () => ReactNode;
}

const FormInput = forwardRef<HTMLInputElement, InputProps>(({ renderIcon, className, ...props }, ref) => {
  const { error } = useFormField();
  const hasError = !!error;

  return (
    <div>
      <div className={cn({ relative: hasError, "rounded-md shadow-sm": hasError })}>
        <Input
          {...props}
          ref={ref}
          className={cn(
            "read-only:cursor-not-allowed read-only:border-0 read-only:bg-gray-100 read-only:text-gray-400 read-only:focus:ring-0",
            { "border border-red-400 focus-visible:ring-0 dark:border-red-400": hasError },
            className
          )}
        />
        <div className="absolute inset-y-0 right-0 flex flex-row items-center space-x-1 pr-3">
          <div>
            {renderIcon?.()}
            {hasError && (
              <span className="pointer-events-none">
                <AlertCircle size={16} className="text-red-500 dark:text-red-400" aria-hidden="true" />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

FormInput.displayName = "Input";
export default FormInput;
