import { Header } from "@/components/header/header";
import PageFooter from "@/layout/PageFooter";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

export default function MainLayout({ children }: PropsWithChildren) {
  return (
    <>
      <div className="flex h-full flex-col">
        {/*<PageNavBar />*/}
        <Header />
        <main className="flex-1 ">
          <div className="mx-auto sm:max-w-7xl">{children ? children : <Outlet />}</div>
        </main>
        <PageFooter />
      </div>
    </>
  );
}
