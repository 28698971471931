import FormError from "@/components/form/formError";
import FormInput from "@/components/form/formInput";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { FieldWithGenerationType } from "@/model/field.typing";
import { useUpdateFieldMutation } from "@/query/field.query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export function EditField({ editField, onClosed }: { editField: FieldWithGenerationType; onClosed: () => void }) {
  const { t } = useTranslation();
  const { form, onSubmit, isLoading, isSuccess, onReset, i18nErrorMessage } = useUpdateFieldMutation(editField);

  useEffect(() => {
    if (isSuccess) {
      onClosed();
      onReset();
    }
  }, [isSuccess, onClosed, onReset]);

  return (
    <Sheet
      open={!!editField}
      onOpenChange={() => {
        onClosed();
      }}
    >
      <SheetContent>
        <SheetHeader>
          <SheetTitle>
            {t("update-fields.title")} {editField?.name}
          </SheetTitle>
          <SheetDescription>{t("update-fields.description")}</SheetDescription>
        </SheetHeader>
        <Form {...form}>
          {/*eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("update-fields.name.label")}</FormLabel>
                  <FormControl>
                    <FormInput
                      type="text"
                      autoComplete="name"
                      placeholder={t("update-fields.name.placeholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("update-fields.field-description.label")}</FormLabel>
                  <FormControl>
                    <FormInput
                      type="text"
                      autoComplete="description"
                      placeholder={t("update-fields.field-description.placeholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}

            <Button type="submit" disabled={isLoading}>
              {t("update-fields.save")}
            </Button>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
