import { IllustrationLoading } from "@/components/svgs/Illustrations";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

function FullPageLoader() {
  const { t } = useTranslation();
  return (
    <main className="flex min-h-full flex-col items-center md:justify-center">
      <div className="py-16 text-center">
        <div className="mb-10 hidden sm:block sm:w-64 md:w-80">
          <IllustrationLoading />
        </div>
        <div className="flex items-center space-x-4 dark:text-slate-200">
          <Loader2 className="h-8 w-8" />
          <span className="text-3xl font-bold text-primary">{t("loader.loading")}</span>
        </div>
      </div>
    </main>
  );
}

export default FullPageLoader;
