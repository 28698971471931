import FormError from "@/components/form/formError";
import FormInput from "@/components/form/formInput";
import FormPasswordInput from "@/components/form/passwordInput";
import Logo from "@/components/svgs/Logo";
import AnchorLink from "@/components/ui/anchorLink";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import PageFooter from "@/layout/PageFooter";
import { executeLoggedUserQuery, useSignInMutation } from "@/query/user.query";
import { QueryClient } from "@tanstack/react-query";
import { Loader2Icon } from "lucide-react";
import { PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LoaderFunctionArgs, redirect, useLoaderData, useLocation, useNavigate } from "react-router-dom";

const imageId = Math.floor(Math.random() * 6) + 1;

export function loader(queryClient: QueryClient) {
  return async ({ request }: LoaderFunctionArgs) => {
    let user = null;
    try {
      user = await executeLoggedUserQuery(queryClient);
    } catch (error) {
      console.error(error);
    }
    if (user) {
      throw redirect("/app");
    }
    return new URL(request.url).searchParams.get("redirectTo");
  };
}

function useGetRedirecUrl() {
  const location = useLocation();
  const redirectToFromParam = useLoaderData() as string;
  const redirectFromLocationState = (location.state as { pathname?: string })?.pathname ?? "/app";
  const redirectLink = redirectToFromParam ?? redirectFromLocationState;
  return redirectLink;
}

function SigninPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const redirectLink = useGetRedirecUrl();
  const { form, onSubmit, i18nErrorMessage, isLoading: isQueryLoading, isSuccess } = useSignInMutation();
  const isSubmitSuccessful = form.formState.isSubmitSuccessful;
  const isRedirecting = isSuccess && isSubmitSuccessful;
  const isLoading = isQueryLoading || isRedirecting;

  useEffect(() => {
    if (isRedirecting) {
      navigate(redirectLink);
    }
  }, [navigate, isRedirecting, redirectLink]);

  return (
    <AuthenticationLayout title={t("login.title")}>
      <Form {...form}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
          <div className="text-center">
            <h2 className="mb-3 text-3xl font-semibold tracking-tight text-secondary-foreground">{t("login.title")}</h2>
            <h2 className="text-muted-foreground">{t("login.description")}</h2>
            <input type="hidden" name="redirectTo" defaultValue={redirectLink} />
          </div>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormInput
                    disabled={isLoading}
                    type="email"
                    autoComplete="email"
                    placeholder={t("form-fields.email.label")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormPasswordInput
                    disabled={isLoading}
                    autoComplete="current-password"
                    placeholder={t("form-fields.password.label")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex items-center justify-between">
            <FormField
              control={form.control}
              name="rememberMe"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Checkbox
                      label={t("login.remember-me")}
                      disabled={isLoading}
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <AnchorLink to="/account/forgot-password" label={t("login.forgot-password")} />
          </div>
          {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
          <Button disabled={isLoading} type="submit" className="w-full text-lg">
            {isLoading ? <Loader2Icon className="mr-2 h-4 w-4 shrink-0 animate-spin" /> : null}
            {t("login.sign-in")}
          </Button>
        </form>
      </Form>
    </AuthenticationLayout>
  );
}

function AuthenticationLayout({ children }: PropsWithChildren<{ title: string }>) {
  return (
    <main className="flex min-h-full">
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full max-w-full object-cover"
          src={`/images/banner-${imageId}.jpg`}
          alt="banner"
        />
        <div className="absolute inset-0 bg-gray-300 mix-blend-multiply" />
        <div className="absolute inset-0 mx-10 hidden translate-y-60 text-white">
          <h1 className="text-5xl font-semibold tracking-widest">Maelab</h1>
          <h2 className="mb-8 text-2xl tracking-widest">MODELING TERRITORIES</h2>
          <h3 className="font-serif text-2xl tracking-wide">
            Modéliser et évaluer les transitions agroécologiques et bioéconomiques des territoires
          </h3>
        </div>
      </div>
      <div className="flex w-full flex-1 flex-col sm:w-1/2 lg:w-1/3 xl:w-1/4">
        <div className="flex h-full flex-col justify-between">
          <div className="flex h-full w-full flex-1 sm:items-center">
            <div className="w-full min-w-full">
              <div className="mx-auto sm:my-12 sm:max-w-sm">
                <Logo className="mx-auto w-72" />
                <div className="mt-8 px-4 sm:px-0">{children}</div>
              </div>
            </div>
          </div>
          <PageFooter />
        </div>
      </div>
    </main>
  );
}

export default SigninPage;
