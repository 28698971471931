import { useDidMount } from "rooks";
import { useThemeStore } from "store/useThemeStore";

/**
 * On load will detect theme and sync it with local storage and switch document classes accordingly
 */
function ThemeProvider() {
  const detectTheme = useThemeStore((state) => state.load);

  useDidMount(() => {
    detectTheme(); // trigger theme detection
  });

  // no need to render anything
  return null;
}

export default ThemeProvider;
