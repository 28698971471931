export const displayWarning = () => {
  if (window.top == window && window.console) {
    setTimeout(console.log.bind(console, "%c%s", "color: red; background: yellow; font-size: 24px;", "WARNING!"));
    setTimeout(
      console.log.bind(
        console,
        "%c%s",
        "font-size: 18px;",
        "Using this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.\nDo not enter or paste code that you do not understand."
      )
    );
  }
};

export function testMe(toto: number): number {
  return toto;
}
