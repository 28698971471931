import { Checkbox } from "@/components/ui/checkbox";
import { FormControl, FormDescription, FormField, FormItem, FormLabel } from "@/components/ui/form";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { FieldPath, UseFormReturn } from "react-hook-form";
export interface Props<T extends Record<string, unknown>, N extends FieldPath<T>> {
  name: N;
  form: UseFormReturn<T>;
  label: string;
  description?: string;
  className?: string;
}

export function CheckboxForm<T extends Record<string, unknown>, N extends FieldPath<T>>({
  name,
  form,
  description,
  label,
  children,
  className,
}: PropsWithChildren<Props<T, N>>) {
  return (
    <>
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem className={clsx("flex flex-col gap-2 space-y-0", className)}>
            <div className="flex flex-row items-start space-x-3 space-y-0">
              <FormControl>
                <Checkbox checked={field.value as boolean} onCheckedChange={field.onChange} />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>{label}</FormLabel>
                {description ? <FormDescription>{description}</FormDescription> : null}
              </div>
            </div>
            <div className="ml-8">{children}</div>
          </FormItem>
        )}
      />
    </>
  );
}
