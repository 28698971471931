import { Separator } from "@/components/ui/separator";
import type { PropsWithChildren, ReactNode } from "react";

type Props = {
  title: string | ReactNode;
  description?: string | ReactNode;
  actionsRender?: () => ReactNode;
};

export function PageContainer({ title, description, actionsRender, children }: PropsWithChildren<Props>) {
  return (
    <div className="m-4 flex-1 sm:mx-12 lg:mx-16">
      <div className="flex flex-col flex-wrap gap-4 py-2 sm:flex-row sm:items-center sm:justify-between md:py-6">
        <div className="flex-1 shrink-0">
          <h2 className="text-4xl font-bold leading-tight md:text-4xl lg:text-5xl lg:leading-[1.1]">{title}</h2>
          {description && <p className="mt-1 text-muted-foreground">{description}</p>}
        </div>
        <div>{actionsRender?.() ?? null}</div>
      </div>
      <Separator />
      {children}
    </div>
  );
}
