import { Button } from "@/components/ui/button";
import { cn } from "@/utils/utils";
import { CheckIcon } from "@radix-ui/react-icons";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { ChevronsUpDown } from "lucide-react";
import { PropsWithChildren, ReactNode } from "react";

export interface Props {
  renderSelected: () => ReactNode;
}

function Combobox({ renderSelected, children }: PropsWithChildren<Props>) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className={cn("w-full justify-between", "h-9 border")}>
          {renderSelected()}
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
        <Command>
          <CommandInput placeholder="Search" />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>{children}</CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function ComboboxItem({
  onSelect,
  value,
  isSelected,
  children,
}: PropsWithChildren<{ value: string; onSelect: () => void; isSelected: boolean }>) {
  return (
    <CommandItem value={value} onSelect={onSelect}>
      <div
        className={cn(
          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
          isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible"
        )}
      >
        <CheckIcon className={cn("h-4 w-4")} />
      </div>
      <span>{children}</span>
    </CommandItem>
  );
}

Combobox.Item = ComboboxItem;
export default Combobox;
