import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PropsWithChildren } from "react";

export const DEFAULT_QUERY_OPTIONS = {
  retry: 2,
  staleTime: 30000, // 30 seconds
  refetchOnWindowFocus: true,
  refetchOnReconnect: true,
};

export const QUERY_CLIENT: QueryClient = new QueryClient({
  defaultOptions: {
    queries: DEFAULT_QUERY_OPTIONS,
  },
});

function ReactQueryProvider({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={QUERY_CLIENT}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
    </QueryClientProvider>
  );
}

export default ReactQueryProvider;
