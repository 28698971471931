import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useFetchers, useNavigation } from "react-router-dom";
import { useSpinDelay } from "spin-delay";
import { useDidMount } from "rooks";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";

let firstRender = true;

export function useIsLoading(): boolean {
  const fetchers = useFetchers();
  const { state } = useNavigation();
  const nbrOfInProgressFetchingQueries = useIsFetching();
  const nbrOfInProgressMutatingQueries = useIsMutating();

  return (
    nbrOfInProgressFetchingQueries > 0 ||
    nbrOfInProgressMutatingQueries > 0 ||
    state !== "idle" ||
    fetchers.some((f) => ["loading", "submitting"].includes(f.state))
  );
}

export default function AppLoadingMessage() {
  const isLoading = useIsLoading();
  const { t } = useTranslation();

  const [words, setWords] = useState<Array<string>>([
    t("loader.messages.1"),
    t("loader.messages.2"),
    t("loader.messages.3"),
  ]);

  const showLoader = useSpinDelay(isLoading, {
    delay: 300,
    minDuration: 800,
  });

  useEffect(() => {
    if (firstRender) return;
    if (!isLoading) return;

    const interval = setInterval(() => {
      setWords(([first, ...rest]) => [...rest, first]);
    }, 1800);

    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    if (showLoader) {
      setWords([t("loader.messages.1"), t("loader.messages.2"), t("loader.messages.3")]);
    }
  }, [showLoader, t]);

  useDidMount(() => (firstRender = false));

  const action = words[0];

  return (
    <AnimatePresence>
      {showLoader ? (
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
          className={clsx("fixed inset-x-0 z-50", "top-[4.4rem]")}
        >
          <div className="flex flex-row-reverse px-4">
            <div className="relative rounded-lg border bg-white px-4 py-2 text-primary shadow-lg dark:border-slate-500 dark:bg-slate-800">
              <div className="flex items-center space-x-2">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                <div className="ml-4 inline-grid">
                  <AnimatePresence>
                    <div className="col-start-1 row-start-1 flex overflow-hidden text-sm font-medium">
                      <motion.span
                        key={action}
                        initial={{ y: 15, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -15, opacity: 0 }}
                        transition={{ duration: 0.25 }}
                        className="flex-none"
                      >
                        {action}
                      </motion.span>
                    </div>
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
