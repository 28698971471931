import ErrorPage from "@/components/ErrorPage";
import { QUERY_CLIENT } from "@/providers/ReactQueryProvider";
import { ActivateAccountPage, loader as activateAccountLoader } from "@/routes/account/activate";
import { ForgotPasswordPage } from "@/routes/account/forgotPassword";
import { ResetPasswordPage, loader as resetPasswordLoader } from "@/routes/account/resetPassword";
import { loader as adminLoader } from "@/routes/admin/adminLoader";
import CreateOrganizationPage from "@/routes/admin/organization/createOrganization";
import UpdateOrganizationPage, { loader as editOrganizationLoader } from "@/routes/admin/organization/editOrganization";
import { OrganizationDetailPage, loader as organizationDetailLoader } from "@/routes/admin/organization/organization";
import OrganizationsPage, { loader as organizationsLoader } from "@/routes/admin/organization/organizations";
import CreateUserPage from "@/routes/admin/user/createUser";
import EditUserPage, { loader as editUserLoader } from "@/routes/admin/user/editUser";
import UsersPage, { loader as usersLoader } from "@/routes/admin/user/users";
import { DashboardPage } from "@/routes/dashboard";
import NotFoundPage from "@/routes/error/notFound";
import ServerErrorPage from "@/routes/error/serverError";
import FieldDetailPage from "@/routes/field/fieldDetail";
import FieldsPage from "@/routes/field/fields";
import AuthRootLayout, { loader as authLoader } from "@/routes/layout/authLayout";
import MainLayout from "@/routes/layout/mainLayout";
import SigninPage, { loader as loginLoader } from "@/routes/signin";
import { AddSimulationPage, loader as addSimulationLoader } from "@/routes/simulation/addSimulation";
import { SimulationsPage } from "@/routes/simulation/simulations";
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import ThemeProvider from "./providers/ThemeProvider";
import { FieldMapPage } from "./routes/field/fieldMap";
import SimulatedPage from "./routes/simulation/simulated";
import { loader as SimulatorLoader, SimulatorPage } from "./routes/simulator/simulator";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        index={true}
        element={<SimulatorPage />}
        loader={SimulatorLoader(QUERY_CLIENT)}
        errorElement={<ErrorPage />}
      />
      <Route
        element={
          <>
            <Outlet />
            <ThemeProvider />
          </>
        }
      >
        <Route
          path="app"
          id="root"
          loader={authLoader(QUERY_CLIENT)}
          element={<AuthRootLayout />}
          errorElement={
            <MainLayout>
              <ErrorPage />
            </MainLayout>
          }
        >
          {/* END-USER ROUTES */}
          <Route index={true} element={<DashboardPage />} />
          <Route path="simulations" element={<Outlet />}>
            <Route index={true} element={<SimulationsPage />} />
            <Route index={true} element={<SimulatedPage />} />
            <Route path="resutl/:fieldId/:fieldGeneration/:organizationId/:simulationId" element={<SimulatedPage />} />
            <Route
              path="add/:fieldId/:generation"
              loader={addSimulationLoader(QUERY_CLIENT)}
              element={<AddSimulationPage />}
            />
          </Route>
          <Route path="case-study" element={<Outlet />}>
            <Route index={true} element={<FieldsPage />} />
            <Route path=":id" element={<FieldDetailPage />} />
            <Route path=":id/:generation/map" element={<FieldMapPage />} />
          </Route>
          <Route path="organizations" element={<Outlet />}>
            <Route path=":id" loader={organizationDetailLoader(QUERY_CLIENT)} element={<OrganizationDetailPage />} />
          </Route>
          <Route path="simulations" element={<Outlet />}>
            <Route path="add" element={<AddSimulationPage />} loader={addSimulationLoader(QUERY_CLIENT)} />
          </Route>

          {/* ADMIN ROUTES */}
          <Route path="admin" loader={adminLoader(QUERY_CLIENT)} element={<Outlet />}>
            <Route path="organizations" element={<Outlet />}>
              <Route index={true} loader={organizationsLoader(QUERY_CLIENT)} element={<OrganizationsPage />} />
              <Route path="add" element={<CreateOrganizationPage />} />
              <Route
                path="edit/:id"
                loader={editOrganizationLoader(QUERY_CLIENT)}
                element={<UpdateOrganizationPage />}
              />
              <Route path=":id" loader={organizationDetailLoader(QUERY_CLIENT)} element={<OrganizationDetailPage />} />
            </Route>
            <Route path="users" element={<Outlet />}>
              <Route index={true} loader={usersLoader(QUERY_CLIENT)} element={<UsersPage />} />
              <Route path="add" element={<CreateUserPage />} />
              <Route path="edit/:id" loader={editUserLoader(QUERY_CLIENT)} element={<EditUserPage />} />
            </Route>
          </Route>
        </Route>

        {/* ACCOUNT ROUTES */}
        <Route
          path="signin"
          shouldRevalidate={() => false}
          loader={loginLoader(QUERY_CLIENT)}
          element={<SigninPage />}
        />
        <Route path="account/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="account/reset-password" loader={resetPasswordLoader} element={<ResetPasswordPage />} />
        <Route path="account/activate" loader={activateAccountLoader} element={<ActivateAccountPage />} />

        {/* ERROR ROUTES */}
        <Route path="error/404" element={<NotFoundPage />} />
        <Route path="error/500" element={<ServerErrorPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Route>
  )
);

export function AppRoutes() {
  return <RouterProvider router={router} />;
}

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}
