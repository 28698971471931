import { PageContainer } from "@/components/PageContainer";
import FormError from "@/components/form/formError";
import FormInput from "@/components/form/formInput";
import OrganizationMultiSelect from "@/components/organization/organizationMultiSelect";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import AnchorLink from "@/components/ui/anchorLink";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { useCreateUserMutation } from "@/query/user.query";
import { AlertTriangleIcon, ArrowLeft } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function CreateUserPage() {
  const { t } = useTranslation();
  const { form, onSubmit, onReset, i18nErrorMessage, isLoading, isSuccess } = useCreateUserMutation();
  return (
    <PageContainer
      title={t("create-user.title")}
      description={t("create-user.description")}
      actionsRender={() => {
        return (
          <Button asChild variant="outline">
            <Link to="/app/admin/users" className="flex flex-nowrap items-center space-x-1">
              <ArrowLeft className="h-5 w-5" />
              <span>{t("create-user.back-to-users")}</span>
            </Link>
          </Button>
        );
      }}
    >
      {isSuccess ? (
        <div className="mt-6">
          <Card>
            <CardHeader>
              <CardTitle>{t("create-user.success.title")}</CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>{t("create-user.success.description")}</CardDescription>
            </CardContent>
            <CardFooter className="gap-2">
              <Button variant="outline">
                <Link to="/app/admin/users" className="flex flex-nowrap items-center space-x-1">
                  <span>{t("create-user.back-to-users")}</span>
                </Link>
              </Button>
              <Button onClick={onReset}>{t("create-user.success.create-another")}</Button>
            </CardFooter>
          </Card>
        </div>
      ) : (
        <div className="mt-6 max-w-lg">
          <Form {...form}>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
              <FormField
                control={form.control}
                name="organizationIds"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form-fields.organization.label")}</FormLabel>
                    <div>
                      <OrganizationMultiSelect
                        onSelect={(organizations) => field.onChange(organizations.map((o) => o.id))}
                        selectedIds={field.value}
                      />
                    </div>
                    <FormDescription className="inline-flex items-center gap-1">
                      <span>{t("create-user.organization.hint")}</span>
                      <AnchorLink
                        to={"/app/admin/organizations"}
                        label={t("create-user.organization.hint-link")}
                        className="text-[0.8rem]"
                      />
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="fullname"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form-fields.fullname.label")}</FormLabel>
                    <FormControl>
                      <FormInput
                        type="text"
                        autoComplete="name"
                        placeholder={t("form-fields.fullname.placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form-fields.email.label")}</FormLabel>
                    <FormControl>
                      <FormInput
                        type="email"
                        autoComplete="email"
                        placeholder={t("form-fields.email.placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>{t("create-user.email.hint")}</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div>
                <FormField
                  control={form.control}
                  name="isAdmin"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">{t("create-user.isAdmin.label")}</FormLabel>
                        <FormDescription>{t("create-user.isAdmin.hint")}</FormDescription>
                      </div>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                {form.getValues("isAdmin") === true ? (
                  <Alert variant="warning" className="mt-2">
                    <AlertTriangleIcon className="h-4 w-4" />
                    <AlertTitle>{t("create-user.isAdmin.warning.title")}</AlertTitle>
                    <AlertDescription>{t("create-user.isAdmin.warning.description")}</AlertDescription>
                  </Alert>
                ) : null}
              </div>

              {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
              <div className="grid grid-rows-2 gap-2 sm:grid-cols-2">
                <Button disabled={isLoading} variant="default" type="submit" className={"w-full"}>
                  {t("create-user.create")}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      )}
    </PageContainer>
  );
}

export default CreateUserPage;
