import Logo from "@/components/svgs/Logo";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
const imageId = Math.floor(Math.random() * 6) + 1;
export function HeroSection() {
  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <Logo className="w-32" />
                <div className="mt-24 sm:mt-32 lg:mt-16"></div>
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Innover pour un futur durable
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Chez MAELab, nous combinons technologie et créativité pour développer des solutions innovantes qui
                  répondent aux défis de demain.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Button size={"lg"} onClick={() => scrollToElement("simulation")}>
                    Découvrez notre simulateur
                  </Button>
                  <Button variant={"link"} asChild>
                    <Link to={"/app"}>Utilisez notre application</Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 hidden sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:block lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="relative hidden w-0 flex-1">
                <img
                  className="absolute inset-0 h-full w-full max-w-full object-cover"
                  src={`/images/banner-${imageId}.jpg`}
                  alt="banner"
                />
              </div>
              <div className="[clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative h-96 px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                  <img
                    className="absolute inset-0 h-full w-full max-w-full object-cover"
                    src={`/images/banner-${imageId}.jpg`}
                    alt="banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}
