import api, { TOKEN_KEY } from "@/api/api";
import { LoggedUserSchema, UnloggedUser, UnloggedUserSchema, User, UserSchema } from "@/model/user.typing";
import { z } from "zod";

export async function requestSignIn(email: string, password: string, rememberMe: boolean): Promise<void> {
  const response = await api.post<{ token: string; user: unknown }>("/v1/authentication/login", {
    email,
    password,
    rememberMe,
  });
  if (response.data?.token) {
    localStorage.setItem(TOKEN_KEY, response.data.token);
  }
}

export async function requestForgotPassword(email: string, signal?: AbortSignal) {
  await api.post<void>("/v1/authentication/forgot-password", { email }, { signal });
}

export async function requestResetPassword(
  body: { uuid: string; token: string; password: string },
  signal?: AbortSignal
) {
  await api.post<void>("/v1/authentication/reset-password", body, { signal });
}

export async function requestActivateAccount(
  body: { uuid: string; token: string; password: string },
  signal?: AbortSignal
) {
  await api.post<void>("/v1/authentication/activate", body, { signal });
}

export async function requestSignOut() {
  localStorage.removeItem(TOKEN_KEY);
  return Promise.resolve();
}

export async function fetchTokenValidity(
  token: string,
  uuid: string,
  tokenType: "ResetPassword" | "EmailConfirmation",
  signal?: AbortSignal
): Promise<UnloggedUser | null> {
  try {
    const isEmailConfirmation = tokenType === "EmailConfirmation";
    const url = `/v1/authentication/validate-token/${uuid}/${token}?isEmailConfirmation=${isEmailConfirmation}`;
    const response = await api.get<{ user: unknown }>(url, { signal });
    if (response.data.user === null) {
      return null;
    }
    return UnloggedUserSchema.parse(response.data.user);
  } catch (error) {
    console.error(error);
    return Promise.resolve(null);
  }
}

export async function fetchLoggedUser(signal?: AbortSignal) {
  const response = await api.get("/v1/user/me", {
    signal,
    validateStatus: (status) => {
      return status === 200 || status === 401 || status === 404; // don't throw error for these statuses
    },
  });
  const result = LoggedUserSchema.safeParse(response.data);
  if (result.success) {
    return result.data;
  }
  return null;
}

export async function fetchUsers(signal?: AbortSignal): Promise<User[]> {
  const response = await api.get("/private/v1/users", {
    signal,
  });
  return z.array(UserSchema).parse(response.data);
}

export async function fetchUser(userId: number, signal?: AbortSignal): Promise<User> {
  const response = await api.get(`/private/v1/users/${userId}`, {
    signal,
  });
  return UserSchema.parse(response.data);
}

export async function requestCreateUser(
  body: { email: string; fullname: string; organizationIds: number[]; isAdmin: boolean },
  signal?: AbortSignal
) {
  const { email, fullname, isAdmin, organizationIds } = body;
  await api.post<void>(
    "/private/v1/users",
    { email, fullname, isAdmin, organization_ids: organizationIds },
    { signal }
  );
}

export async function requestUpdateUser(
  userId: number,
  body: { fullname: string; activated: boolean; isAdmin: boolean; organizationIds: number[] },
  signal?: AbortSignal
) {
  await api.put<void>(`/private/v1/users/${userId}`, body, { signal });
}
