import { useThemeStore } from "store/useThemeStore";

function Logo({ className = "" }: { className?: string }) {
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const fillColor = isDarkMode ? "rgb(241, 245, 249)" : "rgb(15, 23, 42)";

  return (
    <svg
      className={className}
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 1280 383"
    >
      <path
        fill="#f1f1ef"
        d="M161.5 4.5a8.435 8.435 0 0 1 4 .5l120 70a1164.485 1164.485 0 0 0 40 21.5v189a96.517 96.517 0 0 0-12 5.5 16908.245 16908.245 0 0 1-150 86.5A4686.207 4686.207 0 0 1 4.5 286a4.934 4.934 0 0 0-3-.5v-189a738.343 738.343 0 0 0 31-16.5c43.128-25.22 86.128-50.387 129-75.5Z"
      />
      <path
        fill="#b5dbf5"
        d="M161.5 9.5a8.435 8.435 0 0 1 4 .5 6036.73 6036.73 0 0 0 156 89.5 268.978 268.978 0 0 1-1 32.5 1719.342 1719.342 0 0 1-89 38 11589.943 11589.943 0 0 0-146-109 25.522 25.522 0 0 1-4-4.5 2355.788 2355.788 0 0 0 80-47Z"
      />
      <path
        fill="#a6b969"
        d="M187.5 189.5a2675.924 2675.924 0 0 0-64-22.5 391.224 391.224 0 0 0-43-11c-3.615-.732-6.948-2.065-10-4-2.583-3.55-2.417-7.05.5-10.5a135.01 135.01 0 0 0 12-10c5.116-7.002 4.616-13.502-1.5-19.5l-20-10c-5.648-4.127-9.148-9.627-10.5-16.5l-3-3c.488 11.846 5.988 20.68 16.5 26.5l13 5c4.957 5.347 5.29 11.014 1 17-7.4 2.782-13.9 6.949-19.5 12.5-4.288 9.703-1.454 16.203 8.5 19.5a356.01 356.01 0 0 1 61 18l30 16c1.641 1.129 2.641 2.629 3 4.5a3785.119 3785.119 0 0 1-111 49 971.31 971.31 0 0 0-42-11 2469.053 2469.053 0 0 1-2-140c23.306-13.322 46.64-26.655 70-40a24741.944 24741.944 0 0 1 151 112.5 87.45 87.45 0 0 1-11 6 308.768 308.768 0 0 1-29 11.5Z"
      />
      <path
        fill={fillColor}
        d="M667.5 96.5a193.29 193.29 0 0 1 27.5 1c22.847 61.688 45.681 123.355 68.5 185a166.506 166.506 0 0 1-25.5-1 1958.684 1958.684 0 0 0-19.5-50.5 938.363 938.363 0 0 0-75 .5 1159.747 1159.747 0 0 0-18 51h-26c22.673-62.01 45.34-124.01 68-186Zm13 33c1.392 1.773 2.559 3.773 3.5 6a1318.494 1318.494 0 0 0 26.5 71.5 561.343 561.343 0 0 1-58-.5 1226.636 1226.636 0 0 0 28-77Z"
      />
      <path
        fill={fillColor}
        d="M1174.5 96.5c9.36-.332 18.69.002 28 1 22.82 61.613 45.49 123.28 68 185-8.59.317-17.09-.017-25.5-1a4623.915 4623.915 0 0 0-18.5-51c-25.34-.333-50.68 0-76 1l-18 50c-8.64.998-17.31 1.331-26 1 22.68-62.014 45.34-124.014 68-186Zm13 34c2.58 2.82 4.41 6.154 5.5 10 7.58 22.403 15.74 44.57 24.5 66.5a840.83 840.83 0 0 1-58 0c9.77-25.36 19.1-50.86 28-76.5Z"
      />
      <path fill="#3b3b3d" d="M787.5 100.5v-1h111v21h-86c27.593-.999 55.926-1.333 85-1v-19h-110Z" />
      <path
        fill={fillColor}
        d="M429.5 282.5c.008-48.942.008-97.609 0-146-1 48.662-1.333 97.329-1 146h-24v-184c9.006-.166 18.006 0 27 .5a11164.45 11164.45 0 0 1 59 81.5 11164.45 11164.45 0 0 1 59-81.5c8.994-.5 17.994-.666 27-.5v184h-25c.167-48.334 0-96.668-.5-145a6236.066 6236.066 0 0 1-53.5 75 33.225 33.225 0 0 1-14 .5L431 138.5c-.167 48.168-.667 96.168-1.5 144Z"
      />
      <path
        fill={fillColor}
        d="M787.5 100.5h110v19c-29.074-.333-57.407.001-85 1v59h74v21h-75a900.97 900.97 0 0 0 1 60h88v22h-113v-182Z"
      />
      <path fill={fillColor} d="M930.5 98.5h25v161h76v23h-101v-184Z" />
      <path fill={fillColor} d="M1058.5 99.5h24v183h-24v-183Z" />
      <path
        fill="#4e6e8a"
        d="M187.5 189.5c-2.635 2.323-5.635 4.156-9 5.5-5.678 2.174-11.345 4.34-17 6.5-.359-1.871-1.359-3.371-3-4.5l-30-16a356.01 356.01 0 0 0-61-18c-9.954-3.297-12.788-9.797-8.5-19.5 5.6-5.551 12.1-9.718 19.5-12.5 4.29-5.986 3.957-11.653-1-17l-13-5C53.988 103.18 48.488 94.346 48 82.5l3 3c1.352 6.873 4.852 12.373 10.5 16.5l20 10c6.116 5.998 6.616 12.498 1.5 19.5a135.01 135.01 0 0 1-12 10c-2.917 3.45-3.083 6.95-.5 10.5 3.052 1.935 6.385 3.268 10 4a391.224 391.224 0 0 1 43 11 2675.924 2675.924 0 0 1 64 22.5Z"
      />
      <path
        fill="#fbf2aa"
        d="M318.5 137.5c.709-.904 1.709-1.237 3-1 .332 48.836-.001 97.503-1 146 .662-48.678-.005-97.012-2-145Z"
      />
      <path
        fill="#faeb53"
        d="M318.5 137.5c1.995 47.988 2.662 96.322 2 145a741.676 741.676 0 0 1-49.5 27A7788.272 7788.272 0 0 1 57.5 252c87.042-38.19 174.042-76.357 261-114.5Z"
      />
      <path
        fill="#9b7872"
        d="M5.5 243.5a114601.25 114601.25 0 0 1 262 69.5 5268.492 5268.492 0 0 0-102 59c-1.333.667-2.667.667-4 0a640353.947 640353.947 0 0 1-155-89.5 381.127 381.127 0 0 1-1-39Z"
      />
    </svg>
  );
}

export default Logo;
