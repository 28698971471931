import { z } from "zod";

export const UnloggedUserSchema = z.object({
  fullname: z.string(),
  email: z.string(),
});

export const UserSchema = z
  .object({
    id: z.number(),
    activated: z.boolean(),
    profile: z.object({
      organizations: z.array(
        z.object({
          id: z.number(),
          name: z.string(),
        })
      ),
      role: z.string(),
    }),
  })
  .merge(UnloggedUserSchema);

export const LoggedUserSchema = z
  .object({
    exp: z.number(),
    iat: z.number(),
  })
  .merge(UserSchema);

export function isAdmin(loggedUser: LoggedUser | null | undefined): boolean {
  if (loggedUser?.profile === null || loggedUser?.profile === undefined) {
    return false;
  }
  return loggedUser.profile.role === "ADMIN";
}

export type LoggedUser = z.infer<typeof LoggedUserSchema>;
export type User = z.infer<typeof UserSchema>;
export type UnloggedUser = z.infer<typeof UnloggedUserSchema>;

export function getUserInitials(fullname: string | undefined | null): string {
  if (typeof fullname !== "string") {
    return "AV";
  }
  const parts = fullname.split(" ");
  if (parts.length > 1) {
    return `${parts[0][0]}${parts[1][0]}`.toUpperCase();
  }

  if (fullname.length > 1) {
    return `${fullname[0]}${fullname[1]}`.toUpperCase();
  }

  return fullname[0].toUpperCase();
}
