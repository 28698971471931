import { useCallback } from "react";
import { ErrorCode } from "react-dropzone";
import { useTranslation } from "react-i18next";

const includedExtensions = ["zip"];
const includedFileTypes = ["application/zip"];
const excludedFileChars = ["*", '"', "/", "\\", ":", "|", "<", ">", "?"];

export function useValidateZipFile() {
  const { t } = useTranslation();

  const validate = useCallback(
    (file: File) => {
      const noExtError = { code: ErrorCode.FileInvalidType, message: t(`error.file-no-ext`) };
      const invalidExtError = (ext: string) => ({
        code: ErrorCode.FileInvalidType,
        message: t(`error.file-invalid-ext`, { ext }),
      });
      const invalidNameError = (char: string) => ({
        code: ErrorCode.FileInvalidType,
        message: t(`error.file-invalid-chars`, { char }),
      });

      if (!includedFileTypes.includes(file.type)) {
        return invalidExtError(file.type);
      }

      if (file.name) {
        if (!file.name.includes(".")) return noExtError;

        const extension = file.name.split(".").pop();

        if (!extension) return noExtError;

        if (!includedExtensions.includes(extension)) return invalidExtError(extension);

        const invalidChars = excludedFileChars.filter((c) => file.name.includes(c));

        if (invalidChars.length > 0) return invalidNameError(invalidChars.join(","));
      }
      return null;
    },
    [t]
  );

  return validate;
}
