import { PageContainer } from "@/components/PageContainer";
import { DataTable } from "@/components/table/dataTable";
import AnchorLink from "@/components/ui/anchorLink";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { User } from "@/model/user.typing";
import { userListQuery } from "@/query/user.query";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, PlusIcon } from "lucide-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLoaderData, useNavigate } from "react-router-dom";

export function loader(queryClient: QueryClient) {
  return async () => {
    const query = userListQuery();
    return queryClient.getQueryData<User[]>(query.queryKey) ?? (await queryClient.fetchQuery<User[]>(query));
  };
}

function UsersPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;
  const { data } = useQuery({ ...userListQuery(), initialData });

  const columns: ColumnDef<User>[] = useMemo(() => {
    return [
      {
        id: t("users.column.fullname"),
        accessorKey: "fullname",
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("users.column.fullname")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => <div className="capitalize">{row.original.fullname}</div>,
      },
      {
        id: t("users.column.email"),
        accessorKey: "email",
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("users.column.email")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => <div className="lowercase">{row.original.email}</div>,
      },
      {
        id: t("users.column.role"),
        accessorFn: (data) => data.profile.role,
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("users.column.role")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <Badge variant={row.original.profile.role === "ADMIN" ? "default" : "secondary"} className="capitalize">
            {row.original.profile.role.toLowerCase()}
          </Badge>
        ),
      },
      {
        id: t("users.column.organizations"),
        accessorFn: (data) => data.profile.organizations,
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("users.column.organizations")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          const organizations = row.original.profile.organizations;
          if (organizations.length === 1) {
            return <AnchorLink className="capitalize" label={organizations[0].name} to={`/app/admin/organizations`} />;
          }
          if (organizations.length === 0) {
            return <div>-</div>;
          }
          return (
            <AnchorLink
              className="capitalize"
              label={`${row.original.profile.organizations.length} ${t("users.column.organizations")}`}
              to={`/app/admin/organizations`}
            />
          );
        },
      },
      {
        id: t("users.column.status"),
        accessorFn: (data) => (data.activated ? "Active" : "Inactive"),
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("users.column.status")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <div className="capitalize">
            <Badge variant={row.original.activated ? "outline" : "secondary"}>
              {row.original.activated ? t("users.column.active") : t("users.column.inactive")}
            </Badge>
          </div>
        ),
      },
      {
        id: "actions",
        enableHiding: false,
        cell: ({ row }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">{t("users.action-menu.open")}</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => navigate(`/app/admin/users/edit/${row.original.id}`)}>
                  {t("users.action-menu.edit")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ];
  }, [navigate, t]);

  return (
    <PageContainer
      title={t("users.title")}
      description={t("users.description")}
      actionsRender={() => {
        return (
          <Button asChild>
            <Link to="/app/admin/users/add" className="flex flex-nowrap items-center space-x-1">
              <PlusIcon className="h-5 w-5" />
              <span>{t("users.create-user")}</span>
            </Link>
          </Button>
        );
      }}
    >
      <DataTable withSelectColumn={true} data={data} columns={columns} />
    </PageContainer>
  );
}

export default UsersPage;
