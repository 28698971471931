import { Toaster } from "@/components/ui/toaster";
import { Analytics } from "@vercel/analytics/react";
import { AppRoutes } from "AppRoutes";
import CookieConsentProvider from "providers/CookieConsentProvider";
import LanguageProvider from "providers/LanguageProvider";
import ReactQueryProvider from "providers/ReactQueryProvider";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { displayWarning } from "utils/consoleWarning";
import "./i18n/config";
import "./index.css";

if (import.meta.env.PROD) {
  displayWarning();
}

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <ReactQueryProvider>
      <AppRoutes />
    </ReactQueryProvider>

    <LanguageProvider />
    <CookieConsentProvider />
    <Toaster />
    {import.meta.env.PROD ? <Analytics /> : null}
  </StrictMode>
);
