import { isAdmin } from "@/model/user.typing";
import { executeLoggedUserQuery } from "@/query/user.query";
import { QueryClient } from "@tanstack/react-query";
import { json, redirect } from "react-router-dom";

export function redirectToUrl(redirectTo: string | undefined): string {
  //if not defined
  if (typeof redirectTo !== "string") {
    return "/signin";
  }

  // we should not redirect to '/' or '/signin' or '/logout' to prevent infinite redirect loop
  if (redirectTo === "/app" || redirectTo.startsWith("/signin") || redirectTo.startsWith("/logout")) {
    return "/signin";
  }

  return `/signin?${new URLSearchParams({ redirectTo }).toString()}`;
}

export function redirectToLogin(redirectTo: string | undefined): Response {
  return redirect(redirectToUrl(redirectTo));
}

export async function redirectIfNotAdmin(queryClient: QueryClient): Promise<Response> {
  let user = null;
  try {
    user = await executeLoggedUserQuery(queryClient);
  } catch (error) {
    console.error(error);
  }
  if (!user) {
    throw redirect("/signin");
  }
  if (!isAdmin(user)) {
    throw redirect("/error/404");
  }
  return new Response(JSON.stringify(user), { status: 200 });
}

export async function checkUserIsAdminResponse(queryClient: QueryClient) {
  let user = null;
  try {
    user = await executeLoggedUserQuery(queryClient);
  } catch (error) {
    console.error(error);
  }
  if (!user) {
    throw redirect("/signin");
  }

  const isUserAdmin = isAdmin(user);
  return json({ isUserAdmin });
}
