import { organizationQuery } from "@/query/organization.query";
import { CreateOrUpdateOrganization } from "@/routes/admin/organization/createOrUpdateOrganization";
import { toNumber } from "@/utils/mapping";
import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs, redirect, useLoaderData } from "react-router-dom";

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) => {
    const id = toNumber(params["id"]);
    if (id === null) {
      throw redirect("/error/404");
    }
    const organization = await queryClient.fetchQuery(organizationQuery(id));
    if (!organization) {
      throw redirect("/error/404");
    }
    return { organization };
  };
}

function UpdateOrganizationPage() {
  const data = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;
  return <CreateOrUpdateOrganization organization={data.organization} />;
}

export default UpdateOrganizationPage;
