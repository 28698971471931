import {
  fetchOrganization,
  fetchOrganizations,
  fetchUserOrganizations,
  requestCreateOrganization,
  requestUpdateOrganization,
} from "@/api/organization.api";
import { OrganizationDetailType, OrganizationType } from "@/model/organization.typing";
import { DEFAULT_QUERY_OPTIONS } from "@/providers/ReactQueryProvider";
import { getErrorMessage } from "@/utils/error";
import { zodResolver } from "@hookform/resolvers/zod";
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export function organizationQuery(organizationId: number) {
  return {
    queryKey: ["organizations", organizationId],
    queryFn: async ({ signal }: QueryFunctionContext) => await fetchOrganization(organizationId, signal),
    ...DEFAULT_QUERY_OPTIONS,
  };
}

export function organizationListQuery() {
  return {
    queryKey: ["organizations", "list"],
    queryFn: async ({ signal }: QueryFunctionContext) => await fetchOrganizations(signal),
    ...DEFAULT_QUERY_OPTIONS,
  };
}

export function useUserOrganizationList() {
  return useQuery<OrganizationType[]>({
    queryKey: ["organizations", "list", "user"],
    queryFn: async ({ signal }: QueryFunctionContext) => await fetchUserOrganizations(signal),
    ...DEFAULT_QUERY_OPTIONS,
  });
}

export function useOrganizationList() {
  return useQuery<OrganizationType[]>(organizationListQuery());
}

export function useCreateOrUpdateOrganizationMutation(organization?: OrganizationDetailType | null | undefined) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formSchema = z.object({
    name: z.string().trim().min(1, t("create-organization.organization.required")),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: { name: "" },
  });

  const {
    mutate,
    isSuccess,
    isLoading,
    reset,
    error: axiosError,
  } = useMutation<void, AxiosError, z.infer<typeof formSchema>>({
    mutationFn: ({ name }) => {
      if (organization?.id === null || organization?.id === undefined) {
        return requestCreateOrganization({ name });
      }
      return requestUpdateOrganization(organization.id, { name });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["organizations"]);
      await queryClient.refetchQueries(["organizations"]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const i18nErrorMessage = axiosError ? getErrorMessage(axiosError) : null;

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (values) => mutate(values);

  useEffect(() => {
    if (organization?.name) {
      form.setValue("name", organization.name);
    }
  }, [form, organization?.name]);

  const onReset = () => {
    reset();
    form.reset();
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), onReset, i18nErrorMessage, isLoading, isSuccess };
}
