import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useLogoutMutation } from "@/query/user.query";
import { useIsLoading } from "@/components/AppLoadingMessage";

// in seconds
const COUNTER = 30;
const MODAL_TIME = 60 * 10; // 10 minutes
const LOGOUT_TIME = COUNTER + MODAL_TIME;

const LogoutTimer = () => {
  const location = useLocation();
  const isLoading = useIsLoading();
  const { mutate: doLogout } = useLogoutMutation(location.pathname);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(COUNTER);

  const modalTimer = useRef<ReturnType<typeof setTimeout>>();
  const logoutTimer = useRef<ReturnType<typeof setTimeout>>();
  const countDownInterval = useRef<ReturnType<typeof setInterval>>();

  const cleanupTimers = useCallback(() => {
    clearTimeout(modalTimer.current);
    clearTimeout(logoutTimer.current);
    clearInterval(countDownInterval.current);
    setCount(COUNTER);
    setIsOpen(false);
  }, []);

  const logout = useCallback(() => {
    doLogout();
    cleanupTimers();
  }, [cleanupTimers, doLogout]);

  const resetTimers = useCallback(() => {
    cleanupTimers();
    modalTimer.current = setTimeout(() => {
      setIsOpen(true);
      countDownInterval.current = setInterval(() => setCount((state) => state - 1), 1000);
    }, 1000 * MODAL_TIME);
    logoutTimer.current = setTimeout(logout, 1000 * LOGOUT_TIME);
  }, [cleanupTimers, logout]);

  useEffect(() => resetTimers(), [resetTimers, location.key]);
  useEffect(() => {
    if (isLoading) {
      resetTimers();
    }
  }, [resetTimers, isLoading]);
  useEffect(() => cleanupTimers, [cleanupTimers]);

  function closeModal() {
    setIsOpen(false);
    resetTimers();
  }

  return (
    <Dialog open={isOpen} modal={true} onOpenChange={closeModal}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Are you still there?</DialogTitle>
          <DialogDescription>
            You are going to be logged out due to inactivity. Close this modal to stay logged in.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="grid grid-flow-row-dense grid-cols-1 gap-3 sm:grid-cols-2">
          <Button variant="outline" onClick={() => closeModal()}>
            Remain Logged In
          </Button>
          <Button onClick={() => logout()}>{`Logout in ${count}`}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LogoutTimer;
