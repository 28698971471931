import { ButtonGroup } from "@/components/buttonGroup/buttonGroup";
import { SimulatorParams } from "@/model/simulator.typing";
import { RefreshCcwIcon } from "lucide-react";

const options = [
  "Monoculture de maïs",
  "Maïs - Couvert intermédiaire (vesce) - Maïs",
  "Blé - Orge d'hiver - Colza",
  "Blé - Couvert intermédiaire (vesce) - Maïs",
];

export function RotationFilter({
  selected,
  setSelected,
}: {
  selected: SimulatorParams["rotationFR"];
  setSelected: (value: SimulatorParams["rotationFR"]) => void;
}) {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="flex flex-nowrap items-center gap-2 text-sm font-semibold">
        <RefreshCcwIcon className="h-4 w-4" />
        Rotation
      </h3>
      <ButtonGroup
        options={options}
        selected={selected}
        setSelected={(value) => setSelected(value as SimulatorParams["rotationFR"])}
      />
    </div>
  );
}
