import { PageContainer } from "@/components/PageContainer";
import FormError from "@/components/form/formError";
import FormInput from "@/components/form/formInput";
import { Button } from "@/components/ui/button";
import { Card, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { OrganizationDetailType } from "@/model/organization.typing";
import { useCreateOrUpdateOrganizationMutation } from "@/query/organization.query";
import { ArrowLeft } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const CreateOrUpdateOrganization = ({
  organization,
}: {
  organization: OrganizationDetailType | null | undefined;
}) => {
  const isCreateMode = organization === null || organization === undefined;
  const keyPrefix = isCreateMode ? "create-organization" : "update-organization";
  const { t } = useTranslation();
  const { form, onSubmit, onReset, i18nErrorMessage, isLoading, isSuccess } =
    useCreateOrUpdateOrganizationMutation(organization);
  return (
    <PageContainer
      title={t("title", { keyPrefix })}
      description={t("description", { keyPrefix })}
      actionsRender={() => {
        return (
          <Button asChild variant="outline">
            <Link to="/app/admin/organizations" className="flex flex-nowrap items-center space-x-1">
              <ArrowLeft className="h-5 w-5" />
              <span>{t("back-to-organizations", { keyPrefix })}</span>
            </Link>
          </Button>
        );
      }}
    >
      {isSuccess ? (
        <div className="mt-6">
          <Card>
            <CardHeader>
              <CardTitle>{t("success.title", { keyPrefix })}</CardTitle>
            </CardHeader>
            <CardFooter className="gap-2">
              <Button variant="outline">
                <Link to="/app/admin/organizations" className="flex flex-nowrap items-center space-x-1">
                  <span>{t("back-to-organizations", { keyPrefix })}</span>
                </Link>
              </Button>
              <Button onClick={onReset}>{t("success.create-another", { keyPrefix })}</Button>
            </CardFooter>
          </Card>
        </div>
      ) : (
        <div className="mt-6 max-w-lg">
          <Form {...form}>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("organization.label", { keyPrefix })}</FormLabel>
                    <FormControl>
                      <FormInput
                        type="text"
                        autoComplete="name"
                        placeholder={t("organization.placeholder", { keyPrefix })}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
              <div className="grid grid-rows-2 gap-2 sm:grid-cols-2">
                <Button disabled={isLoading} variant="default" type="submit" className={"w-full"}>
                  {t("create", { keyPrefix })}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      )}
    </PageContainer>
  );
};
