import { AppError } from "model/common.typing";
import { AxiosError } from "axios";
import { z } from "zod";

// if you add another status don't forget to add translation to error.statuses
type HttpStatuses = 0 | 400 | 401 | 403 | 404 | 500;

const ApiValidationResponseSchema = z.object({
  statusCode: z.number(),
  message: z.string(),
  error: z.string(),
});

const appErrorSchema = z.object({
  status: z.number(),
  errors: z.record(z.string()),
  message: z.string(),
});

export function isAppError(error: unknown): error is AppError {
  return !!appErrorSchema.safeParse(error);
}

export function getErrorMessage(error: unknown): string {
  if (isAppError(error)) {
    return error.i18nMessage;
  }
  return `error.statuses.500`;
}

export function mapToAppError(axiosError: AxiosError): AppError {
  const isNetworkError = axiosError.message.toLowerCase().includes("network");
  const status = (isNetworkError ? 0 : axiosError.response?.status ?? 500) as HttpStatuses;
  const defaultI18nMessage = `error.statuses.${status}`;

  const parseValidationResult = ApiValidationResponseSchema.safeParse(axiosError.response?.data);

  if (parseValidationResult.success) {
    return {
      axiosError,
      status: parseValidationResult.data.statusCode,
      i18nMessage: parseValidationResult.data.error ? parseValidationResult.data.error : defaultI18nMessage,
      message: parseValidationResult.data.message,
    };
  }

  // try to get text from message
  let message: string | undefined;
  const responseWithError = z.object({ message: z.string() }).safeParse(axiosError.response);
  if (responseWithError.success) {
    message = responseWithError.data.message;
  }

  return {
    status,
    message,
    i18nMessage: defaultI18nMessage, // default based on the response status
    axiosError,
  };
}
