import { Button } from "../ui/button";

export interface ButtonGroupProps {
  options: string[] | { id: string | null; label: string }[];
  selected: string;
  setSelected: (value: string | null) => void;
  className?: string;
}

export function ButtonGroup({ options, selected, setSelected, className }: ButtonGroupProps) {
  const handleSelect = (value: string | null) => {
    if (value !== selected) {
      setSelected(value);
    }
  };
  return (
    <div className={className ?? "flex flex-row flex-wrap items-center gap-x-4 gap-y-2 text-xs"}>
      {options.map((option) => {
        const label = typeof option === "string" ? option : option.label;
        const id = typeof option === "string" ? option : option.id;
        return (
          <Button
            key={id}
            size={"sm"}
            className="whitespace-nowrap"
            variant={id === selected ? "default" : "outline"}
            onClick={() => handleSelect(id)}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
}
