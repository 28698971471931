import Combobox from "@/components/combobox/combobox";
import { useState} from "react";

interface Props {
    defaultRenderSelect: string
    items : string[];
    onSelect: (newOperations: string[] | undefined) => void;
}

export function MultiSelectComboBox({defaultRenderSelect, items, onSelect}: Props){
    const [operationsState, setOperationsState] = useState<string[]>([])

    const handleSelect = (operationName: string) => {
        setOperationsState((prevState) => {
            const updatedState = prevState.includes(operationName)
                ? prevState.filter((item) => item !== operationName) // remove if already selected
                : [...prevState, operationName]; // add if not selected

            operationsState.length > 0 ? onSelect(updatedState) : onSelect(undefined);
            return updatedState;
        });
    };

    return <>
        <Combobox renderSelected={() => {return operationsState.length > 0 ? operationsState.join(' | ') : defaultRenderSelect}}>
            {
                items.map((operationName: string) => {
                    const isSelected = operationsState.includes(operationName);
                    return <Combobox.Item key={operationName} value={operationName} isSelected={isSelected} onSelect={() => handleSelect(operationName)}>{operationName}</Combobox.Item>})
            }
        </Combobox>
    </>
}