import { PageContainer } from "@/components/PageContainer";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { FieldBaseGenerationType, FieldWithGenerationType } from "@/model/field.typing";
import { isAdmin } from "@/model/user.typing";
import { useFetchFieldDetail, useFieldGenerationDetail } from "@/query/field.query";
import { useLoggedUser } from "@/query/user.query";
import { useCurrentOrganizationStore } from "@/store/useCurrentOrganizationStore";
import { LanguageCode, getLocale } from "@/utils/locale";
import { format, formatRelative } from "date-fns";
import { MapIcon, PlayCircleIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { EditField } from "./EditField";
import { AddFieldGenerationDialog } from "./addFieldGeneration";

function FieldDetailPage() {
  const { data: loggedUser } = useLoggedUser();
  const isUserAdmin = isAdmin(loggedUser);
  const { id: fieldId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { currentOrganization } = useCurrentOrganizationStore();
  const [editField, setEditField] = useState<null | FieldWithGenerationType>(null);
  const [fieldGeneration, setAddFieldGeneration] = useState<null | FieldWithGenerationType>(null);
  const { field, isLoading } = useFetchFieldDetail(fieldId, currentOrganization?.id);

  if (isLoading || !field) {
    return null;
  }

  return (
    <PageContainer
      title={field.name}
      description={field.description ?? ""}
      actionsRender={() => {
        return isUserAdmin ? (
          <div className="flex items-center gap-2">
            <Button variant={"outline"} size="sm" onClick={() => setAddFieldGeneration(field)}>
              {t("fields.add-generation")}
            </Button>
            <Button variant={"outline"} size="sm" onClick={() => setEditField(field)}>
              {t("fields.edit-field")}
            </Button>
          </div>
        ) : null;
      }}
    >
      {editField ? <EditField editField={editField} onClosed={() => setEditField(null)} /> : null}
      {fieldGeneration ? (
        <AddFieldGenerationDialog field={fieldGeneration} toggleDialog={() => setAddFieldGeneration(null)} />
      ) : null}
      <div className="mt-4">
        {field.generations.map((generation) => (
          <FieldGenerationDetail key={generation.generation_id} generation={generation} fieldId={field.id} />
        ))}
      </div>
    </PageContainer>
  );
}

function FieldGenerationDetail({ generation, fieldId }: { fieldId: string; generation: FieldBaseGenerationType }) {
  const { t, i18n } = useTranslation();
  const locale = getLocale(i18n.language as LanguageCode);
  const { data } = useFieldGenerationDetail(fieldId, generation.generation);
  return (
    <Card className="mt-4">
      <CardContent className="pt-6">
        <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
          <div className="grid flex-1 grid-cols-1 gap-x-4 gap-y-2 text-sm text-muted-foreground md:grid-cols-2">
            <div className="flex items-center gap-1">
              <span className="whitespace-nowrap">{t("fields.labels.generation")}</span>
              <span className="text-sm font-semibold text-primary">{generation.generation}</span>
            </div>
            <div className="flex items-center gap-1">
              <span className="whitespace-nowrap">{t("fields.labels.parcelCount")}</span>
              <span className="text-sm font-semibold text-primary">{data?.parcels_count ?? 0}</span>
            </div>
            <div className="flex items-center gap-1">
              <span className="whitespace-nowrap">{t("fields.labels.pedoZones")}</span>
              <span className="text-sm font-semibold text-primary">{data?.pedo_zones.join(",")}</span>
            </div>
            <div className="flex items-center gap-1">
              <span className="whitespace-nowrap">{t("fields.labels.minMaxYear")}</span>
              <span className="text-sm font-semibold text-primary">
                {data?.min_year} - {data?.max_year}
              </span>
            </div>
            <div className="flex items-center gap-1">
              <span>{t("fields.labels.uploaded")}</span>
              <span
                className="whitespace-nowrap text-sm font-semibold capitalize text-primary"
                title={format(generation.upload_date, "PPp", { locale })}
              >
                {formatRelative(generation.upload_date, new Date(), { locale })}
              </span>
            </div>
          </div>

          <div>
            <Button asChild variant="ghost" type="button">
              <Link to={`/app/case-study/${fieldId}/${generation.generation}/map`} className="inline-flex gap-2">
                <MapIcon className="h-5 w-5" />
                {t("fields.map-view")}
              </Link>
            </Button>
            <Button asChild variant="ghost" type="button">
              <Link to={`/app/simulations/add/${fieldId}/${generation.generation}`} className="inline-flex gap-2">
                <PlayCircleIcon className="h-5 w-5" />
                {t("fields.create-simulation")}
              </Link>
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default FieldDetailPage;
