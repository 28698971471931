import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { FieldPath, UseFormReturn } from "react-hook-form";

export interface Props<T extends Record<string, unknown>, N extends FieldPath<T>> {
  name: N;
  form: UseFormReturn<T>;
  defaultValue?: string;
  label?: string;
  options: Array<{ id: string; name: string }>;
}

export function RadioGroupForm<T extends Record<string, unknown>, N extends FieldPath<T>>({
  name,
  form,
  defaultValue,
  label,
  options,
}: Props<T, N>) {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="space-y-3">
          {label ? <FormLabel>{label}</FormLabel> : null}
          <FormControl>
            <RadioGroup onValueChange={field.onChange} defaultValue={defaultValue} className="flex flex-col space-y-1">
              {options.map(({ id, name }) => (
                <FormItem key={id} className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value={id} />
                  </FormControl>
                  <FormLabel className="font-normal">{name}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
