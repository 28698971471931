import { useDidMount } from "rooks";
import { useLanguageStore } from "store/useLanguageStore";

/**
 * On load will detect language and sync it with local storage and switch document attribute accordingly
 */
function LanguageProvider() {
  const detectLanguage = useLanguageStore((state) => state.load);

  useDidMount(() => {
    detectLanguage();
  });

  // no need to render anything
  return null;
}

export default LanguageProvider;
