import { fetchTokenValidity, requestSignOut } from "@/api/user.api";
import FormError from "@/components/form/formError";
import FormInput from "@/components/form/formInput";
import FormPasswordInput from "@/components/form/passwordInput";
import Logo from "@/components/svgs/Logo";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import PageFooter from "@/layout/PageFooter";
import { useResetPasswordMutation } from "@/query/user.query";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link, LoaderFunctionArgs, redirect, useLoaderData } from "react-router-dom";

export async function loader({ request }: LoaderFunctionArgs) {
  const token = new URL(request.url).searchParams.get("token");
  const uuid = new URL(request.url).searchParams.get("uuid");
  if (token === null || token === undefined || token.trim().length === 0) {
    throw redirect("/error/404");
  }
  if (uuid === null || uuid === undefined || uuid.trim().length === 0) {
    throw redirect("/error/404");
  }
  await requestSignOut(); // logout user
  const isValid = await fetchTokenValidity(token, uuid, "ResetPassword", request.signal);
  return isValid ? { token, uuid } : null;
}

export function ResetPasswordPage() {
  const tokenRecord = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const { t } = useTranslation();
  const { form, onSubmit, i18nErrorMessage, isSuccess, isLoading } = useResetPasswordMutation(tokenRecord);

  if (tokenRecord === null) {
    return (
      <Container>
        <div className="my-4">
          <div className="text-center">
            <h2 className="text-3xl font-semibold tracking-tight text-secondary-foreground">
              {t("reset-password.title")}
            </h2>
          </div>
          <div className="mt-8 text-center">
            <h2 className="mb-4 font-semibold">{t("reset-password.invalid-token.line1")}</h2>
            <p className="mt-4 text-sm leading-7 text-muted-foreground">{t("reset-password.invalid-token.line2")}</p>
          </div>
          <div className="mt-4 text-center">
            <Button asChild variant="outline" type="button">
              <Link to="/account/forgot-password" replace={true}>
                {t("reset-password.invalid-token.generate-link")}
              </Link>
            </Button>
          </div>
        </div>
      </Container>
    );
  }

  if (isSuccess) {
    return (
      <Container>
        <div className="my-4">
          <div className="text-center">
            <h2 className="text-3xl font-semibold tracking-tight text-secondary-foreground">
              {t("reset-password.success.title")}
            </h2>
          </div>
          <div className="mt-8 text-center">
            <h2 className="mb-4 font-semibold">{t("reset-password.success.message")}</h2>
          </div>
          <div className="mt-4 text-center">
            <Button asChild variant="outline" type="button">
              <Link to="/signin">{t("reset-password.success.signin")}</Link>
            </Button>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Form {...form}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
          <div className="text-center">
            <h2 className="mb-3  text-3xl font-semibold tracking-tight text-secondary-foreground">
              {t("reset-password.title")}
            </h2>
            <h2 className="text-muted-foreground">{t("reset-password.description")}</h2>
          </div>
          <FormField
            control={form.control}
            name="uuid"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormInput type="hidden" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="token"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormInput type="hidden" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormPasswordInput
                    autoComplete="new-password"
                    placeholder={t("reset-password.password.placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
          <div className="space-y-2.5">
            <div className="text-sm leading-4 text-muted-foreground">
              <span>{t("reset-password.back-signin-hint")}</span>
            </div>
            <div className="grid grid-rows-2 gap-2 sm:grid-cols-2">
              <Button asChild disabled={isLoading} variant="outline" type="button" className={"w-full"}>
                <Link to="/signin">{t("reset-password.back-signin")}</Link>
              </Button>
              <Button disabled={isLoading} variant="default" type="submit" className={"w-full"}>
                {t("reset-password.reset-password")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </Container>
  );
}

export function Container({ children }: PropsWithChildren) {
  return (
    <main className="flex min-h-full">
      <div className="flex w-full flex-1 flex-col">
        <div className="flex h-full flex-col justify-between">
          <div className="flex h-full w-full flex-1 sm:items-center">
            <div className="w-full min-w-full">
              <div className="mx-auto my-4 sm:my-12 sm:max-w-md">
                <Logo className="mx-auto w-44 sm:w-72" />
                <div className="mt-8 px-4 sm:px-0">{children}</div>
              </div>
            </div>
          </div>
          <PageFooter />
        </div>
      </div>
    </main>
  );
}
