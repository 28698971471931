import { create } from "zustand";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";
import { LucideIcon, MonitorCheck, Moon, Sun } from "lucide-react";

type Theme = "dark" | "light" | "system";

const THEMES: Array<{ id: Theme; icon: LucideIcon }> = [
  { id: "light", icon: Sun },
  { id: "dark", icon: Moon },
  { id: "system", icon: MonitorCheck },
];

interface ThemeStore {
  isDarkMode: boolean;
  themes: typeof THEMES;
  theme: Theme;
  load: () => void;
  setTheme: (theme: Theme) => void;
}

export const useThemeStore = create<ThemeStore>((setState, getState) => {
  function getThemeFromSystem(): Theme {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }

  return {
    isDarkMode: false,
    themes: THEMES,
    theme: "system",
    load: () => {
      const theme = getLocalStorageValue<Theme>("maelia-theme", "read dark theme status") ?? "system";
      getState().setTheme(theme);
    },
    setTheme: (theme) => {
      const isDarkMode = theme === "dark" || (theme === "system" && getThemeFromSystem() === "dark");
      setState((state) => ({ ...state, theme, isDarkMode }));
      setLocalStorageValue("maelia-theme", theme, "Switch theme");
      if (isDarkMode) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    },
  };
});
