import { parseSimulatorCSV } from "@/api/simulator.api";
import { SimulatorParams, SimulatorType } from "@/model/simulator.typing";
import { DEFAULT_QUERY_OPTIONS } from "@/providers/ReactQueryProvider";
import { useQuery } from "@tanstack/react-query";

export function useSimulatorList(params: SimulatorParams) {
  return useQuery(simulatorListQuery(params));
}

export function simulatorListQuery(params: SimulatorParams) {
  const query = {
    queryKey: ["simulator"],
    queryFn: async () => await parseSimulatorCSV(),
    ...DEFAULT_QUERY_OPTIONS,
    select(data: SimulatorType[]) {
      const filterParams = (item: SimulatorType, params: SimulatorParams) => {
        return Object.entries(params).every(([key, value]) => {
          return !value || item[key as keyof SimulatorType] === value;
        });
      };

      const applyFilters = (data: SimulatorType[], params: SimulatorParams) => {
        return data.filter((item) => filterParams(item, params));
      };

      const select = (data: SimulatorType[], params: SimulatorParams) => {
        let result = data;
        if (Object.keys(params).length > 0) {
          result = applyFilters(data, params);
        }
        return result;
      };
      return select(data, params);
    },
  };
  return query;
}
