import AnchorLink from "@/components/ui/anchorLink";
import LanguageDropdown from "components/LanguageDropdown";
import ThemeSwitch from "components/ThemeSwitch";
import { useTranslation } from "react-i18next";

function PageFooter() {
  const { t } = useTranslation();
  return (
    <div className="mx-auto w-full sm:max-w-7xl">
      <footer className="flex flex-col justify-center gap-2 p-4 text-sm text-muted-foreground sm:flex-row">
        <div className="col-span-2 flex flex-1 flex-col items-center gap-2">
          <div className="flex  flex-row items-center gap-1 sm:mb-0 sm:gap-2">
            <AnchorLink label={t("footer.legal")} to="/app" />
            <span className="hidden  sm:block">|</span>
            <AnchorLink to="/app" label={t("footer.terms")} />
            <span className="hidden  sm:block">|</span>
            <AnchorLink to="/app" label={t("footer.privacy")} />
          </div>
          <div className="flex flex-row flex-wrap items-center justify-center gap-x-2">
            <span>{t("footer.inc", { getFullYear: new Date().getFullYear() })}</span>
            <span>
              version: <span className="font-semibold">{import.meta.env.MAELIA_VERSION}</span>
            </span>
            {import.meta.env.MAELIA_PORTAL_ENV !== "production" ? (
              <span>
                env: <span className="font-semibold">{import.meta.env.MAELIA_PORTAL_ENV}</span>
              </span>
            ) : null}
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-x-2">
          <ThemeSwitch />
          <LanguageDropdown />
        </div>
      </footer>
    </div>
  );
}

export default PageFooter;
