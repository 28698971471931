import { OrganizationType } from "@/model/organization.typing";
import { create } from "zustand";

interface State {
  currentOrganization: OrganizationType | null;
  key: string | null;
  setOrganization: (organization: OrganizationType | null) => void;
  init: (userId: number, organizations: OrganizationType[] | undefined) => void;
}

export const useCurrentOrganizationStore = create<State>((set, get) => ({
  currentOrganization: null,
  key: null,
  setOrganization: (organization: OrganizationType | null) => {
    const key = get().key as string;
    set({ currentOrganization: organization });
    if (organization) {
      localStorage.setItem(key, JSON.stringify(organization));
    } else {
      localStorage.removeItem(key);
    }
  },
  init: (userId: number, organizations: OrganizationType[] | undefined) => {
    const key = `orga-user-v2-${userId}`;
    set({ key });
    if (!organizations) {
      return;
    }
    const savedOrganizationString = localStorage.getItem(key);
    if (savedOrganizationString) {
      try {
        const savedOrganization = JSON.parse(savedOrganizationString) as OrganizationType;
        const currentOrganization = organizations.find((o) => o.id === savedOrganization.id);
        if (currentOrganization) {
          set({ currentOrganization });
          return; // loaded from localstorage
        }
      } catch (ignore) {
        /* empty */
      }
    }
    // loaded from first
    const actualOrganization = organizations.filter(({ id }) => id);
    const firstOrganization = actualOrganization.length > 0 ? actualOrganization[0] : null;
    set((state) => ({ ...state, currentOrganization: firstOrganization as OrganizationType }));
    if (firstOrganization) {
      localStorage.setItem(key, JSON.stringify(firstOrganization));
    }
  },
}));
