import { PageContainer } from "@/components/PageContainer";
import { DataTable } from "@/components/table/dataTable";
import AnchorLink from "@/components/ui/anchorLink";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { SimulationType } from "@/model/simulation.typing";
import { simulationListQuery, useGenerateDownloadLink } from "@/query/simulation.query";
import { useCurrentOrganizationStore } from "@/store/useCurrentOrganizationStore";
import { LanguageCode, getLocale } from "@/utils/locale";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { ArrowUpDown } from "lucide-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function SimulationsPage() {
  const { t } = useTranslation();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { data = [] } = useQuery(simulationListQuery(currentOrganization?.id));
  const title = `${currentOrganization?.name ?? ""} ${t("simulations.title")}`.trim();

  return (
    <PageContainer title={title} description={t("simulations.description")}>
      <SimulationDataTable simulations={data} />
    </PageContainer>
  );
}

function SimulationDataTable({ simulations }: { simulations: SimulationType[] }) {
  const { t, i18n } = useTranslation();
  const locale = getLocale(i18n.language as LanguageCode);
  const { mutate, isLoading } = useGenerateDownloadLink();

  const columns: ColumnDef<SimulationType>[] = useMemo(() => {
    return [
      {
        id: t("simulations.column.organization"),
        accessorFn: (row) => row.organization.name,
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("simulations.column.organization")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <AnchorLink
            to={`/app/organizations/${row.original.organization.id}`}
            label={row.original.organization.name}
            className="mx-4 text-sm font-medium capitalize leading-none"
          />
        ),
      },
      {
        id: t("simulations.column.field"),
        accessorFn: (row) => row.field.name,
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("simulations.column.field")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <AnchorLink
            to={`/app/case-study/${row.original.field.id}`}
            label={row.original.field.name}
            className="mx-4 text-sm font-medium capitalize leading-none"
          />
        ),
      },
      {
        id: t("simulations.column.generation"),
        accessorFn: (row) => row.field.generation,
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("simulations.column.generation")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => <div className="lowercase">{row.original.field.generation}</div>,
      },
      {
        id: t("simulations.column.date"),
        accessorFn: (data) => data.updated_at,
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("simulations.column.date")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => <div className="capitalize">{format(row.original.updated_at, "PP p", { locale })}</div>,
      },
      {
        id: t("simulations.column.status"),
        accessorKey: "status",
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {t("users.column.status")}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <div className="capitalize">
            <Badge variant="outline">{row.original.status.toLowerCase()}</Badge>
          </div>
        ),
      },
      {
        id: "actions",
        header: () => <span>{t("simulations.column.actions")}</span>,
        cell: ({ row }) => (
          <DownloadFileButton
            key={row.original.id}
            isLoading={isLoading}
            onClick={() => mutate({ organizationId: row.original.organization.id, simulationId: row.original.id })}
          />
        ),
      },
    ] as ColumnDef<SimulationType>[];
  }, [isLoading, locale, mutate, t]);

  return <DataTable withSelectColumn={false} data={simulations} columns={columns} />;
}

function DownloadFileButton({ isLoading, onClick }: { isLoading: boolean; onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <div className="capitalize">
      <Button disabled={isLoading} variant="ghost" size="sm" onClick={() => onClick()}>
        {t("simulations.column.downloadFiles")}
      </Button>
    </div>
  );
}
