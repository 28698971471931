import { SimulatorType } from "@/model/simulator.typing";
import ReactECharts from "echarts-for-react";
import { EChartsOption } from "echarts-for-react/src/types";
import { LineChartIcon } from "lucide-react";

interface Props {
  simulationData: SimulatorType;
}

function mapToBiodiversity(value: SimulatorType["biodiversite"]): number {
  switch (value) {
    case "trÃ¨s faible":
      return 1;
    case "faible":
      return 2;
    case "moyen":
      return 3;
    case "Ã©levÃ©":
      return 4;
  }
}

function mapToQualiteDuSol(value: SimulatorType["qualite_sol"]): number {
  switch (value) {
    case "très mauvais":
      return 1;
    case "mauvais":
      return 2;
    case "moyen":
      return 3;
  }
}

export function RadarChart({ simulationData }: Props) {
  if (!simulationData || !simulationData.mean_GES) {
    return (
      <div className="flex w-full items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <LineChartIcon className="h-10 w-10 text-gray-500" />
          <p className="max-w-sm text-center text-lg font-medium text-gray-700">
            Nous ne pouvons pas afficher le graphique pour les paramètres sélectionnés.
          </p>
        </div>
      </div>
    );
  }
  const options: EChartsOption = {
    tooltip: {
      show: true,
      valueFormatter: (value: number) => new Intl.NumberFormat("fr-FR", { maximumSignificantDigits: 3 }).format(value),
    },
    radar: {
      axisLabel: {
        show: false,
      },
      axisName: {
        overflow: "break",
        formatter: function (value: string, indicator: { max: number }) {
          return `${value}\n(max:${new Intl.NumberFormat("fr-FR", { maximumSignificantDigits: 3 }).format(
            indicator.max
          )})`;
        },
      },
      scale: true,
      indicator: [
        { name: "GES T CO2eq./ha", max: 14210, min: 980 },
        { name: "Énergie MJ/kg de MS/ha", max: 170000, min: 10000 },
        { name: "Temps de travail (h/ha)", max: 9 },
        { name: "Biodiversité", max: 4 },
        { name: "Qualité du sol", max: 3 },
        { name: "Émission de NH3 (kg de N/ha)", max: 42 },
        { name: "Lixivation (kg N/ha)", max: 100 },
      ],
    },
    series: [
      {
        name: "Résultats intégrés",
        type: "radar",
        data: [
          {
            value: [
              simulationData.mean_GES,
              simulationData.mean_energy,
              simulationData.mean_tps_travail,
              mapToBiodiversity(simulationData.biodiversite),
              mapToQualiteDuSol(simulationData.qualite_sol),
              simulationData.mean_emission_nh3,
              simulationData.mean_lixiviation,
            ],
          },
        ],
      },
    ],
  };
  return <ReactECharts option={options} style={{ minWidth: "70%", minHeight: "70%" }} className="" />;
}
