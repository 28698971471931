import { z } from "zod";

export const SimulationSchema = z.object({
  id: z.string(),
  status: z.string(),
  created_at: z.coerce.date(),
  updated_at: z.coerce.date(),
  organization: z.object({
    id: z.number(),
    name: z.string(),
  }),
  field: z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable().optional(),
    updated_at: z.coerce.date(),
    generation_id: z.string(),
    generation: z.number(),
  }),
});

export type SimulationType = z.infer<typeof SimulationSchema>;
