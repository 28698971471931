import FormError from "@/components/form/formError";
import FormInput from "@/components/form/formInput";
import { OrganizationSelect } from "@/components/organization/organizationSelect";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { useCreateFieldMutation } from "@/query/field.query";
import { useCurrentOrganizationStore } from "@/store/useCurrentOrganizationStore";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UploadFieldFile } from "./uploadFieldFile";

interface Props {
  isOpen: boolean;
  toggleDialog: (isOpen: boolean) => void;
}

export function AddFieldDialog({ isOpen, toggleDialog }: Props) {
  const { t } = useTranslation();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { form, onSubmit, i18nErrorMessage, onReset, isLoading, isSuccess } = useCreateFieldMutation();
  useEffect(() => {
    if (isSuccess) {
      toggleDialog(false);
      onReset();
    }
  }, [isSuccess, toggleDialog, onReset]);

  useEffect(() => {
    if (currentOrganization?.id !== undefined && currentOrganization?.id !== null) {
      form.setValue("organizationId", currentOrganization.id);
    }
  }, [currentOrganization, form]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("create-fields.title")}</DialogTitle>
          <DialogDescription>{t("create-fields.description")}</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
            <div className="space-y-6 py-2 pb-4">
              <FormField
                control={form.control}
                name="organizationId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form-fields.organization.label")}</FormLabel>
                    <div>
                      <OrganizationSelect
                        placeholder={t("form-fields.organization.placeholder")}
                        onValueChange={(organization) => field.onChange(organization)}
                        defaultValue={field.value}
                      />
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("update-fields.name.label")}</FormLabel>
                    <FormControl>
                      <FormInput
                        type="text"
                        autoComplete="name"
                        placeholder={t("update-fields.name.placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("update-fields.field-description.label")}</FormLabel>
                    <FormControl>
                      <FormInput
                        type="text"
                        autoComplete="description"
                        placeholder={t("update-fields.field-description.placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="file"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("generation-upload.label")}</FormLabel>
                    <div>
                      <UploadFieldFile
                        onFileRejection={(rejected) => {
                          form.setError("file", rejected.errors[0]);
                        }}
                        onFileSelection={(file) => {
                          field.onChange(file);
                        }}
                      />
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
            </div>

            <DialogFooter>
              <Button variant="outline" onClick={() => toggleDialog(false)}>
                {t("create-fields.cancel")}
              </Button>
              <Button type="submit" disabled={isLoading}>
                {t("create-fields.save")}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
