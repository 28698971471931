import { SimulatorType } from "@/model/simulator.typing";
import * as Papa from "papaparse";

export async function parseSimulatorCSV() {
  return new Promise<SimulatorType[]>((resolve, reject) => {
    Papa.parse("/data.csv", {
      download: true,
      header: true,
      complete: (result) => resolve(result.data as SimulatorType[]),
      error: (error) => reject(error),
    });
  });
}
