import { clsx } from "clsx";
import FormInput from "components/form/formInput";
import { useFormField } from "components/ui/form";
import { Eye, EyeOff } from "lucide-react";
import { InputHTMLAttributes, forwardRef, useState } from "react";

const FormPasswordInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const computedType = showPassword ? "text" : "password";
  const { error } = useFormField();
  const hasError = !!error;

  return (
    <div className="relative">
      <FormInput {...props} ref={ref} type={computedType} />
      <div className="absolute inset-y-0 right-0 flex flex-row items-center space-x-1 pr-3">
        <>
          <span className={clsx("text-muted-foreground hover:cursor-pointer", { "mr-5": hasError })}>
            {showPassword ? (
              <EyeOff
                size={20}
                aria-hidden="true"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <Eye
                size={20}
                aria-hidden="true"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => setShowPassword(true)}
              />
            )}
          </span>
        </>
      </div>
    </div>
  );
});

FormPasswordInput.displayName = "PasswordInput";
export default FormPasswordInput;
