import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { isAdmin } from "@/model/user.typing";
import { useLoggedUser } from "@/query/user.query";
import { cn } from "@/utils/utils";
import { clsx } from "clsx";
import * as React from "react";
import { ElementRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, LinkProps, NavLink } from "react-router-dom";

const AdminSectionLinks: { title: string; to: string; description: string }[] = [
  {
    title: "organizations.title",
    to: "/app/admin/organizations",
    description: "organizations.description",
  },
  {
    title: "users.title",
    to: "/app/admin/users",
    description: "users.description",
  },
];

const EndUserLinks = [
  {
    name: "navbar.dashboard",
    href: `/app`,
    isActivePattern: "/app",
  },
  {
    name: "navbar.simulations",
    href: `/app/simulations`,
    isActivePattern: "/app/simulations",
  },
  {
    name: "fields.title",
    href: "/app/case-study",
    isActivePattern: "/app/case-study",
  },
];

export function MainNav({ className, ...props }: React.HTMLAttributes<HTMLElement>) {
  const { t } = useTranslation();
  const { data: loggedUser } = useLoggedUser();
  const isUserAdmin = isAdmin(loggedUser);

  return (
    <>
      <nav className={cn("flex items-center space-x-4 lg:space-x-6", className)} {...props}>
        {EndUserLinks.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            end={item.href === "/app"}
            className={({ isActive }) => {
              const activeClass =
                !location.pathname.startsWith("/app/admin") &&
                (isActive ||
                  (item.isActivePattern === "/app" && location.pathname === "/app") ||
                  (item.isActivePattern !== "/app" && location.pathname.indexOf(item.isActivePattern) >= 0));
              return clsx(
                "text-sm font-medium transition-colors hover:text-primary",
                activeClass ? "text-foreground" : "text-foreground/60"
              );
            }}
          >
            {t(item.name)}
          </NavLink>
        ))}
        {isUserAdmin ? <AdminNavs /> : null}
      </nav>
    </>
  );
}

export function AdminNavs() {
  const { t } = useTranslation();
  const isActivePath = location.pathname.indexOf("/app/admin") >= 0;
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger className={clsx(isActivePath ? "text-foreground" : "text-foreground/60", "-ml-3")}>
            {t("navbar.admin")}
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[250px] grid-cols-1 gap-2 p-2 md:w-[300px] lg:w-[400px] ">
              {AdminSectionLinks.map((link) => (
                <ListItem key={link.title} title={t(link.title)} to={link.to}>
                  {t(link.description)}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = forwardRef<ElementRef<"a">, LinkProps>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-1 text-sm leading-snug text-muted-foreground">{children}</p>
        </Link>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
