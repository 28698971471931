import { useLanguageStore } from "store/useLanguageStore";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";

function LanguageDropdown() {
  const { language, languages, setLanguage } = useLanguageStore((state) => ({
    languages: state.languages,
    setLanguage: state.setLanguage,
    language: state.language,
  }));

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="default" className="flex-nowrap gap-2" role="menu">
          <language.icon />
          <span>{language.name}</span>
          <span className="sr-only">Toggle language</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {languages.map(({ id, name, icon: Flag }) => (
          <DropdownMenuCheckboxItem key={id} checked={language.id === id} onClick={() => setLanguage(id)}>
            <div className="flex items-center space-x-1">
              <Flag />
              <span className="text-sm">{name}</span>
            </div>
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default LanguageDropdown;
